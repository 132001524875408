import Header from './header'; 
import Footer from './footer'; 
import React, {useState, useEffect} from 'react';
import ModalShow from './modalShow'; 
import { Modal, Button, Toast } from 'react-bootstrap';


function Homepage(){
  const [isMobile, setIsMobile] = useState(false);
  const godown = (e) =>{
  const targetDiv = document.getElementById('targetDivId');
  targetDiv.scrollIntoView({ behavior: 'smooth' });
  }   
  
      return (
  <div>
     <Header/>
     <div>
     <section className="wrapper" style={{ height: '130vh' }}>
  <div className="container">
    <style>
      {`
        /* Add this CSS within a <style> tag in the section */
        .wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .container {
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        #filmvid {
          object-fit: contain;
          width: 100%;
          height: 139%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center 60%; /* Adjust the vertical position (10% down from the top) */
          z-index: -1;
        }

      

        /* Style for the middle button */
        .middle-button {
          position: absolute;
          top: 65%;
          left: 50%;
          font-size: 10px;
          color: white;
          transform: translate(-50%, -50%);
          z-index: 1;
          display: block
        }

        .middle-button span {
          border-bottom: 1px solid #ffffff; /* Adjust the underline color and style */
          display:block;
        }

    

        /* Style for the bottom button */
        .bottom-button {
          position: absolute;
          top: 83%;
          font-size: 45px;
          color: white;
          left: 50%;
          transform: translateX(-50%);
          display:block;
        }
      `}
    </style>

    <video
      name="vid"
      id="filmvid"
      width="100%"
      height="100%"
      playsInline
      autoPlay
      loop
      muted
    >
      {isMobile && (
        <source src="assets/media/oyeleke4.mov" type="video/mp4" />
      )}
      {!isMobile && (
        <source src="assets/media/oyeleke4.mov" type="video/mp4" />
      )}
    </video>
    <ModalShow />
    {/* Middle Button */}
    <a href='allitems' className="middle-button" >
    <span>SHOP</span>
      </a>

      <i className='bottom-button pe pe-7s-angle-down' onClick={godown}></i>
  </div>
</section>
        <div className="special-category-banner my-banner" id="targetDivId">
          <div className="container-fluid">
            <div className="special-category-banner-content">
              <div className="row">
                <div className="col-sm-4 col-lg-4">
                  <div className="single-special-banner">
                    <figure className="banner-thumbnail">
                      <a href="theresa"><img src="assets/media/theresa/theresa5.jpeg" className="banner-thumb" alt="Category" /></a>
                      <figcaption className="banner-cate-name text-center">
                        <h2 className="banner-title">THERESA COLLECTION</h2>
                        {/* <a href="all" className="btn btn-light">Discover</a> */}
                      </figcaption>
                    </figure>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-4">
                  <div className="single-special-banner">
                    <figure className="banner-thumbnail">
                      <a href="/allitems"><img src="assets/media/theresa/theresa.jpeg" className="banner-thumb" alt="Category" /></a>
                      <figcaption className="banner-cate-name text-center">
                        <h2 className="banner-title">SHOP</h2>
                        {/* <a href="all" className="btn btn-black">Shop Now</a> */}
                      </figcaption>
                    </figure>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-4" >
                  <div className="single-special-banner">
                    <figure className="banner-thumbnail">
                      <a href="/wilderness_pt2"><img src="assets/img/img/wilderness2/15.jpg" className="banner-thumb" alt="Category" /></a>
                      <figcaption className="banner-cate-name text-center">
                        <h2 className="banner-title">WILDERNESS PT_2</h2>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     

      


     <Footer/>
              </div>
               
        );
 
  };

export default Homepage;
