import React, { useState, useEffect } from 'react';
import { Modal, Button, Toast } from 'react-bootstrap';

const ModalShow = () => {
  const [state, setState] = useState({
    email: ''
  });

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value
    }));
  };

  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const hasModalBeenShown = sessionStorage.getItem('modalShown');

  useEffect(() => {
    let delayToShowModal;
    if (!hasModalBeenShown) {
      delayToShowModal = setTimeout(() => {
        setShowModal(true);
        sessionStorage.setItem('modalShown', 'true');
      }, 3000);
    }
    return () => clearTimeout(delayToShowModal);
  }, []);

  const handleSubmit = () => {
    setShowToast(true);
    setShowModal(false);
  };

  return (
    <div className="modal-wrapper">
      {showModal && (
        <div className="modal show" style={{ display: 'block', position: 'initial' }}>
          <Modal.Dialog>
            <Modal.Body closeButton onHide={handleClose}>
              <i style={{ float: 'right', fontSize: '30px' }} onClick={handleClose} className="pe pe-7s-close"></i>
              <br />
              <br />
              <p>Join the conversation. Sign up to receive updates about the collection.</p>
              <br />
              <div className="newsletter-area">
                <form>
                  <input type="email" value={state.email} onChange={handleChange} id="email" name="email" autoComplete="off" placeholder="Enter Email Address" required />
                </form>
              </div>
              <br />
              <Button variant="dark" style={{ float: 'right' }} onClick={handleSubmit}>
                Submit
              </Button>
            </Modal.Body>
          </Modal.Dialog>
        </div>
      )}
    </div>
  );
};

export default ModalShow;
