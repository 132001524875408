import React, {useState}  from 'react';
import Header from './header'; 
import Footer from './footer'; 
import Countries from './countries';
import './mystyle.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import axios from "axios";
import { Alert } from 'react-bootstrap';

// import { EcwidApi } from 'ecwid';


function Register(){
  const MySwal = withReactContent(Swal)
  let history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const baseUrl = "https://backend.oyeleke.com/api/registerCustomer";
  // const baseUrl = "http://localhost:6998/api/registerCustomer";
  const [state , setState] = useState({
      email: '',
      fname: '',
      lname: '',
      password: '',
      country: '',
      street: '',
      street2: '',
      city: '',
      postalcode: ''
    })

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
      }
    };


    const registrationData = {
      name: state.fname+' '+state.lname, 
      email: state.email,
      password: state.password,
      country: 'not collected',
      street: 'not collected',
      street2: 'not collected',
      city: 'not collected',
      postalcode: 'not collected'
      
      };

    const handleRegSubmit = (e) => {
      window.location.href="https://oyeleke.com/allitems#!/~/account";
    }

    const handleLoginSubmit = (e) => {
      console.log(registrationData)
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z]{6,}$/;
      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
      if (!state.fname ||!state.lname || !state.password || !state.email){
        console.log(state.fname || state.password||state.email)
        MySwal.fire({
          title: <p>warning</p>,
          text: 'Please fill all required fields',
          icon: 'warning',
          confirmButtonColor: '#000000',
          didOpen: () => {
          },
        }).then(() => {})
      }else if(!isValidEmail(state.email)) {
        MySwal.fire({
            title: 'Failed',
            icon: 'error',
          confirmButtonColor: '#000000',
            text: <p>Email is invalid</p>,
            didOpen: () => {},
          }).then(() => {});
        }else if(!passwordRegex.test(state.password)) {
          MySwal.fire({
               title: 'Failed',
                icon: 'warning',
                confirmButtonColor: '#000000',
              text: 'Password must contain at least one uppercase letter, and be minimum 6 characters.',
              didOpen: () => {},
            }).then(() => {});
      }else{
        MySwal.fire({
          title: <p>please wait...</p>,
          didOpen: () => {
            MySwal.showLoading()
            axios.post(baseUrl, registrationData, axiosConfig)
          .then((response) => {
            console.log("response received: ", response);
              Swal.close()
              Swal.fire({
                  title: response.data.data,
                  text: response.data.message,
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonText: "Okay",
                 confirmButtonColor: '#000000',
                  closeOnCancel: true,
                }).then(result => {
                  console.log("response received: ", response);
                  // sessionStorage.setItem("instv_tokenize", JSON.stringify(response.data.accessToken));
                  // sessionStorage.setItem("instv_fname", JSON.stringify(response.data.user.firstname));
                  // sessionStorage.setItem("instv_fi", JSON.stringify(response.data.user.id));
                  // window.location.href="dashboard";
                 window.location.href="https://oyeleke.com/allitems#!/~/account";
                })
            })
            .catch((err) => {
              Swal.close()
              console.log("AXIOS ERROR: ", err);
              if (err.code == 'ERR_NETWORK'){
                MySwal.fire({
                  title: 'Failed',
                   icon: 'warning',
                   confirmButtonColor: '#000000',
                 text: err.message,
                //  text: err.message,
                 didOpen: () => {},
               }).then(() => {
                // window.location.reload()
               });
              }
              else if (err.response.data.error.errorMessage){
                MySwal.fire({
                  title: 'Failed',
                   icon: 'warning',
                   confirmButtonColor: '#000000',
                 text: err.response.data.error.errorMessage,
                //  text: err.message,
                 didOpen: () => {},
               }).then(() => {
                // window.location.reload()
               });
              } else {
                MySwal.fire({
                  title: 'Failed',
                   icon: 'warning',
                   confirmButtonColor: '#000000',
                 text: err.message,
                //  text: err.message,
                 didOpen: () => {},
               }).then(() => {
                // window.location.reload()
               });
              }
            })
          },
        }).then(() => {
        })
      }
    }
    

      const handleChange = (e) => {
          const {id , value} = e.target   
          setState(prevState => ({
              ...prevState,
              [id] : value
          }))
      }

      const handleChange2 = (fieldName) => (selectedOption) => {
        setState((prevState) => ({
          ...prevState,
          [fieldName]: selectedOption.value
        }));
      };

   

    //   const ecwid = new EcwidApi({
    //     storeId: '31093036',
    //     accessToken: 'public_6QgPvTQPLapDmLqsmPVCzkqrdiVsywj5',
    //   });

      const loginUser = async (email, password) => {
       
      };

      const handleLogin = () => {
        loginUser(state.email, state.password);
      };
      
      return (
              <div>
     <Header/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
        <div id="my-account-page-wrapper" className="pt-88 pt-md-58 pt-sm-48 pb-50 pb-md-20 pb-sm-10 putmarginleft">
          <div className="container">
            <div className="row">
            <div className="col-md-12">
                <div className="my-account-item-wrapper">
                  <div className="my-account-form-wrap">
                    <form action="#" method="post">
                    <div className='row'>
                    <div className="single-form-input col-lg-8">
                        <input style={{height:'5px'}} type="text" id="fname" placeholder='FIRST NAME *' onChange={handleChange} value={state.fname} name='fname' required />
                      </div>
                      <div className="single-form-input col-lg-8">
                        <input style={{height:'5px'}} type="text" id="lname" placeholder='LAST NAME *' onChange={handleChange} value={state.lname} name='lname' required />
                      </div>
                      <div className="single-form-input col-lg-8">
                        <input style={{height:'5px'}} type="text" id="email" placeholder='EMAIL *' onChange={handleChange} value={state.email} name='email' required />
                      </div>

                      <div className="single-form-input col-lg-8">
                        <input style={{height:'5px'}} type="password" placeholder='PASSWORD *' id="password" onChange={handleChange} value={state.password} name='password' required />
                      </div>
                      <div className="col-lg-8">
                      <button onClick={handleLoginSubmit} className="btn btn-custom-block"  type="button">
                        Register
                      </button>
                      </div>
                      <br/>
                      <br/>
                      <br/> 
                      <br/>
                     <div className="d-flex align-items-center col-lg-8 mb-14" style={{ width: '120px !important' }}>
                      <button onClick={handleRegSubmit} className="btn btn-custom-block"  type="button">
                       <small> Already Registered? LOGIN</small>
                      </button>
                    </div>
                      </div>
                       
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         {/* <Footer/> */}
              </div>
               
        );
 
  };

export default Register;
