import React, {useState} from 'react';
import Header from './header'; // Import your Header component
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from './footer'; 


function Wilderness_pt2lookbook(){
  const images = [
    'assets/img/img/wilderness2/1.jpg',
    'assets/img/img/wilderness2/2.jpg',
    'assets/img/img/wilderness2/3.jpg',
    'assets/img/img/wilderness2/5.jpg',
    'assets/img/img/wilderness2/4.jpg',
    'assets/img/img/wilderness2/7.jpg',
    'assets/img/img/wilderness2/11.jpg',
    'assets/img/img/wilderness2/12.jpg',
    'assets/img/img/wilderness2/13.jpg',
    'assets/img/img/wilderness2/14.jpg',
    'assets/img/img/wilderness2/15.jpg',
    'assets/img/img/wilderness2/16.jpg',
    'assets/img/img/wilderness2/17.jpg',
    'assets/img/img/wilderness2/18.jpg',
    'assets/img/img/wilderness2/19.jpg',
    'assets/img/img/wilderness2/20.jpg',
    'assets/img/img/wilderness2/21.jpg',
    'assets/img/img/wilderness2/22.jpg',
    'assets/img/img/wilderness2/23.jpg',
    'assets/img/img/wilderness2/9.jpg',
    'assets/img/img/wilderness2/10.jpg',
    'assets/img/img/wilderness2/8.jpg',
  ];

  const [selectedImage, setSelectedImage] = useState('');
  
  const handleShow = (image) => {
    setSelectedImage(image);
  };
  

  return (
    <div>
      <Header/>
      <br/>
      <br/>
      <br/> 
      <br/>
      <br/>
      <br/>
      <br/> 
     <center style={{marginLeft: '-3%'}}> <p> <a href='/wilderness_pt2film' style={{color: 'black'}}> FILM </a> &nbsp;&nbsp;|&nbsp; <a style={{color: 'black'}} href='/wilderness_pt2'> CAMPAIGN </a> &nbsp;&nbsp;|&nbsp;  <a style={{color: 'black', fontWeight: '700'}} href='/Wilderness_pt2lookbook'> LOOKBOOK</a> &nbsp;&nbsp;&nbsp; </p></center>

      <br/>
      <div>
      <div className="special-category-banner my-banner">
      <div className="special-category-banner-content">
      <div className="row justify-content-center">
        {images.map((image, index) => (
          <div key={index} className="col-12 col-sm-6 col-md-4 mb-4" onClick={() => handleShow(image)} data-bs-toggle="modal" data-bs-target="#quickViewModal">
            <img
              src={image}
              alt={`Image ${index + 1}`}
              className="img-fluid w-100"
            />
          </div>
        ))}
      </div>
      </div>
      </div>

      <div className="modal" id="quickViewModal" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-body">
          <Carousel selectedItem={images.indexOf(selectedImage)} showThumbs={false}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Single Product ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      </div>
      </div>
      </div>
      <Footer/>
          </div>
          
      );

      };
export default Wilderness_pt2lookbook;
