import React, {useState} from 'react';
import Header from './header'; // Import your Header component
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from './footer'; 



function Divinemercycampaign(){

  const images = [
    'assets/img/img/BOB0030.jpg',
    'assets/img/img/BOB0013.jpg',
    'assets/img/img/BOB0042.jpg',
    'assets/img/img/BOB0045.jpg',
    'assets/img/img/BOB0057.jpg',
    'assets/img/img/BOB0059.jpg',
    'assets/img/img/BOB0077.jpg',
    'assets/img/img/BOB0061.jpg',
    'assets/img/img/BOB0071.jpg',
    'assets/img/img/BOB0082.jpg',
    'assets/img/img/BOB0088.jpg',
    'assets/img/img/BOB0015.jpg',
    'assets/img/img/BOB0016.jpg',
  ];
  
  const [selectedImage, setSelectedImage] = useState('');
  
  const handleShow = (image) => {
    setSelectedImage(image);
  };

      return (
      <div>
        <Header/>
        <br/>
        <br/>
        <br/> 
        <br/>
        <br/>
        <br/>
        <br/> 
       <center style={{marginLeft: '-3%'}}> <p> <a href='/divinemercyfilm' style={{color: 'black'}}> FILM </a> &nbsp;&nbsp;|&nbsp; <a style={{color: 'black'}} href='/divinemercy'> CAMPAIGN </a> &nbsp;&nbsp;|&nbsp;  <a style={{color: 'black', fontWeight: '700'}} href='/divinemercycampaign'> LOOKBOOK</a> &nbsp;&nbsp;&nbsp; </p></center>
        <br/>
        <div>
        <div className="special-category-banner my-banner">
        <div className="special-category-banner-content">
        <div className="row justify-content-center">
          {images.map((image, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-4 mb-4" onClick={() => handleShow(image)} data-bs-toggle="modal" data-bs-target="#quickViewModal">
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="img-fluid w-100"
              />
            </div>
          ))}
        </div>
        </div>
        </div>
  
        <div className="modal" id="quickViewModal" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <Carousel selectedItem={images.indexOf(selectedImage)} showThumbs={false}>
              {images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`Single Product ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        </div>
        </div>
        </div>
        <Footer/>
            </div>
               
        );
 
  };

export default Divinemercycampaign;
