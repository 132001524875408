import React from 'react';
import Header from './header'; 
import Footer from './footer';
import './mystyle.css';


function Privacy(){
      return (
              <div>
     <Header/>
     <br/>
     <br/>
     <br/> 
     <br/>
        <div id="blog-page-wrapper" className="pt-90 pt-md-60 pt-sm-50 pb-48 pb-md-20 pb-sm-10">
          <div className="container">
            <div className="blog-page-post-wrap">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="single-blog-post-wrap">
                    <p style={{textAlign: 'justify', lineHeight: '2.0',}}>
                    <h4>PRIVACY POLICY</h4>
                    <br/>
                    <h5>EFFECTIVE DATE:  DECEMBER 9, 2023</h5>
                    <br/>
                    <h5> LAST UPDATED:  DECEMBER 21, 2023</h5>
                    <br/>
                    THE PRIVACY POLICY DESCRIBES HOW OYELEKE (“OYELEKE,” “OUR,” “WE,” AND “US”) PROCESSES PERSONAL
                     INFORMATION ABOUT YOU.  THIS PRIVACY POLICY APPLIES TO INFORMATION COLLECTED BOTH ONLINE AND OFFLINE, 
                     INCLUDING WHEN YOU ACCESS OR OUR WEBSITE (“SITE”), CONTACT OUR CUSTOMER SERVICE TEAM, ENGAGE WITH US ON 
                     SOCIAL MEDIA, COMMUNICATE WITH US,
                    OR OTHERWISE INTERACT WITH US (TOGETHER WITH THE SITE, THE “SERVICES”).
                    <br/>
                    <br/>
                    IF YOU HAVE ANY QUESTIONS, PLEASE CONTACT US AS PROVIDED BELOW.  YOU MAY DOWNLOAD A COPY OF THIS PRIVACY POLICY BY CLICKING HERE.  
                    IF YOU HAVE A DISABILITY AND NEED TO RECEIVE THIS PRIVACY POLICY IN A DIFFERENT FORMAT, 
                    PLEASE CONTACT US AT INFO@OYELEKE.COM.
                    <br/>
                    <br/>
                    THIS PRIVACY POLICY DOES NOT APPLY TO PERSONAL INFORMATION THAT WE COLLECT IN CONNECTION WITH YOUR WORK FOR US AS AN EMPLOYEE OR INDEPENDENT CONTRACTOR. 
                     THAT INFORMATION IS GOVERNED BY OUR WORKFORCE PRIVACY POLICY.
                    <br/>
                    <br/>
                    PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND OUR POLICIES AND PRACTICES.  BY USING THE SITE AND SERVICES OR PROVIDING YOUR PERSONAL INFORMATION TO US, 
                    YOU CONSENT TO THE USE OF YOUR INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY.  IF YOU DO NOT AGREE WITH ANY TERM IN THIS PRIVACY POLICY,
                    YOU SHOULD NOT ACCESS AND USE THE SITES AND PLEASE DO NOT OTHERWISE PROVIDE US WITH ANY PERSONAL INFORMATION.
                     <br/>
                     <br/>
                     <b>INFORMATION WE COLLECT</b>
                        <br/>
                        <br/>
                        <b>1. PERSONAL INFORMATION:</b>
                        <p style={{textTransform: 'uppercase',lineHeight: '2.0'}}>
                        When you create an account, make a purchase, or interact with our website, we may collect personal information such as your name, email address, shipping address, and payment details.
                        <br/> 
                        <br/> 
                        <u>Device Information:</u> We may collect information about the device you use to access our website, including the device type, operating system, and browser type.
                        <br/>
                        <u>Usage Information:</u> We gather data about your interactions with our website, including pages visited, products viewed, and the duration of your visit.
                        <br/>
                        <br/>
                        <b>2. How We Use Your Information:</b>
                        <br/>
                        <u>Order Processing</u>: We use your personal information to process and fulfill your orders, including shipping and payment processing.
                        <br/>
                        <u>Personalization</u>: To enhance your shopping experience, we may use your information to personalize product recommendations, promotions, and content.
                        <br/>
                        <u>Communication</u>: We may send you email updates about your orders, promotions, and newsletters. You can opt-out of promotional emails at any time.
                        <br/>
                        <u>Analytics</u>: We use data analytics to improve our website, services, and user experience.
                        <br/>
                        <br/>
                       <b> 3. Data Security:</b>
                       <br/>
                        We implement industry-standard security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is entirely secure. Therefore, we cannot guarantee absolute security.
                        <br/>
                        <br/>
                        <b>4. Third-Party Services:</b>
                       <br/>
                        Oyeleke may utilize third-party services for payment processing, shipping, and analytics. These third parties have their own privacy policies, and we recommend reviewing them for a comprehensive understanding of how your information is handled.
                        <br/>
                       <br/>
                       <b> 5. Cookies:</b>
                       <br/>
                        Our website uses cookies to enhance your browsing experience. You can manage cookie preferences through your browser settings.
                        <br/>
                       <br/>
                        <b>6. Your Choices:</b>
                       <br/>
                        You have the right to access, update, or delete your personal information. If you have any questions or concerns about your privacy, please contact us at [contact@oyeleke.com].
                        <br/>
                       <br/>
                       <b>7. IDENTIFIERS:</b>
                       <br/>
                       WE COLLECT SOCIAL SECURITY NUMBER, DRIVER’S LICENSE NUMBER, MILITARY ID NUMBER, PASSPORT NUMBER, STATE IDENTIFICATION CARD NUMBER, I-9 DOCUMENTATION, TAX OR NATIONAL IDENTIFICATION NUMBER, INTERNET PROTOCOL ADDRESS, ACCOUNT USERNAMES AND PASSWORDS, SIGNATURE, AND SOCIAL MEDIA USER NAMES.
                            <br/>
                            <br/>
                            <b>8. COMMERCIAL INFORMATION:</b>
                            <br/>
                            WE COLLECT RECORDS OF PRODUCTS OR SERVICES PURCHASED, OBTAINED, OR CONSIDERED AND PURCHASING HISTORIES.
                            <br/>
                            <br/>
                            <b>9. FINANCIAL INFORMATION:</b>
                                <br/>
                             WE COLLECT CREDIT CARD INFORMATION, PAYMENT CARD NUMBER, PAYMENT CARD EXPIRATION DATE AND CVV CODE, AND BILLING ADDRESS.
                             <br/>
                            <br/>

                            <b>10. COOKIES, ANALYTICS, AND OTHER TRACKING TECHNOLOGY</b>
                            <br/>
                            <u>COOKIES AND TRACKING TECHNOLOGY</u>
                            <br/>
                            WE WANT YOU TO BE AWARE THAT CERTAIN ONLINE TOOLS EXIST TO HELP US SERVE AND RECOGNIZE YOU WHEN YOU VISIT OUR SITE. WE RECEIVE AND STORE CERTAIN TYPES OF INFORMATION WHEN YOU VISIT OUR SITE. WHEN YOU ACCESS THE SITE, WE (INCLUDING COMPANIES WE WORK WITH) MAY PLACE SMALL DATA FILES ON YOUR COMPUTER OR OTHER DEVICE. 
                            THESE DATA FILES MAY BE WEB BEACONS, COOKIES, CLEAR GIFS, PIXEL TAGS, E-TAGS, LOG FILES, OR OTHER LOCAL STORAGE PROVIDED BY YOUR BROWSER OR ASSOCIATED APPLICATIONS.  
                            THESE TRACKING TECHNOLOGIES, WHICH ARE OFTEN REFERRED TO COLLECTIVELY AS COOKIES, ALLOW US TO UNDERSTAND HOW USERS NAVIGATE TO AND AROUND OUR SITE, VIEW DIFFERENT PAGES, ACCESS CONTENT AND REQUEST SERVICES. 
                            WE MAY USE THEM, FOR EXAMPLE, TO KEEP TRACK OF YOUR PREFERENCES AND PROFILE INFORMATION AND COLLECT GENERAL USAGE AND VOLUME STATISTICAL INFORMATION. IF YOU WANT TO REMOVE OR BLOCK COOKIES, YOU MAY BE ABLE TO UPDATE YOUR BROWSER SETTINGS.  
                            YOU CAN FIND INSTRUCTIONS ON HOW TO MANAGE COOKIES ON DIFFERENT TYPES OF WEB BROWSERS AT WWW.ALLABOUTCOOKIES.ORG. TO LEARN MORE ABOUT INTEREST-BASED ADVERTISING, TRACKING TECHNOLOGIES, AND HOW TO OPT OUT.  
                            PLEASE CLICK HERE TO CHANGE YOUR PREFERENCES REGARDING COOKIES.
                            <br/>
                            <br/>
                        <b>11. Changes to the Privacy Policy:</b>
                             <br/>
                        Oyeleke reserves the right to update this Privacy Policy. We will notify you of any significant changes through the website or email.

                        By continuing to use our website, you signify your acceptance of this Privacy Policy. If you do not agree with the terms outlined herein, please refrain from using our services.

                        Thank you for choosing Oyeleke for your fashion needs.


                        </p>
                    </p>
                   
                    
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

     <Footer/>
              </div>
               
        );
 
  };

export default Privacy;
