// Header.js
import React, {useState, useEffect} from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import ModalShow from './modalShow';





function Header() {
  const [isMobile, setIsMobile] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // Your search term state
  const [state , setState] = useState({
    search: '',
    password: '',
    email: ''
  })

  // const handleClose = () => {
  //   setShowModal(false);
  // };

  // const [showModal, setShowModal] = useState(true);
  // const [showToast, setShowToast] = useState(false);
  //   // const hasModalBeenShown = sessionStorage.getItem('modalShown');


  //   // useEffect(() => {
  //     let delayToShowModal;
  //     if (!hasModalBeenShown) {
  //       delayToShowModal = setTimeout(() => {
  //         setShowModal(true);
  //         sessionStorage.setItem('modalShown', 'true');
  //       }, 3000);
  //     }
  //     return () => clearTimeout(delayToShowModal);
  //   }, []);
    


  // const handleSubmit = () => {

   
  //   setShowToast(true);
  //   setShowModal(false);
  // };

  const handleChange = (e) => {
      const {id , value} = e.target   
      setState(prevState => ({
          ...prevState,
          [id] : value
      }))
  }


  const fetchSearchResults = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.get(`https://app.ecwid.com/api/v3/31093036/products`, {
        headers: {
          accept: 'application/json',
          Authorization: 'secret_c4xyrGkuN8tznfLeat1AQ2qcwAuVAAg1',
        },
      });
  
      // Handle the data according to your needs
      console.log(response.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('403 Forbidden - Check API key or permissions.');
      } else {
        console.error('Error fetching search results:', error);
      }
    }
  };


  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();


  const handleSearch = (e) => {
    e.preventDefault()
    console.log(state.search)
    const searchvalue = state.search;
    window.location.href= "search?search=" + searchvalue;
  }

  const Product = ({ product }) => (
    <div style={{ border: '1px solid #ddd', padding: '10px', margin: '10px' }}>
      <img src={product.thumbnailUrl} alt={product.name} style={{ width: '100px', height: '100px' }} />
      <h3>{product.name}</h3>
      <p>{product.price.formatted}</p>
      <p>{product.description}</p>
      {/* Add more product details as needed */}
    </div>
  );
  

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://app.ecwid.com/script.js?31093036&data_platform=code&data_date=2020-06-07';
    script.charset = 'utf-8';
    script.async = true;
  
    script.onload = () => {
      if (window.Ecwid) {
        window.Ecwid.init();
      }
    };
  
    document.body.appendChild(script);
  
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 1028);
    };
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
  
    return () => {
      // Clean up the script element when the component unmounts
      document.body.removeChild(script);
  
      // Clean up the event listener on component unmount
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);  // Ensure to include setIsMobile in the dependency array if it's part of the component state
  

  return (
    <header id="header-area" className="fixed-top sticky-header" >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-content-wrapper d-flex align-items-center">
              {isMobile && (
                  <div className="header-left-area d-flex align-items-center">
                    <div className="menu-icon-wrap">
                      <button className="menu-icon-bar modalActive" data-mfp-src="#left-offside-popup">
                        <i className="dl-icon-menu5" />
                      </button>
                    </div>
                  </div>
                )}
                
                {!isMobile && (
                   <div className="nav mainmenu d-none d-lg-block" >
                          <li className="dropdown-show"><a style={{fontSize:'15px', color: 'black'}} href="#">Oyeleke</a>
                            <ul className="mega-menu-wrap dropdown-nav d-flex">
                              <li className="mega-menu-item"><a href="#" className="mega-item-title">SHOP</a>
                                <ul>
                                  <li><a href="/tops">TOPS</a></li>
                                  <li><a href="/bottoms">BOTTOMS</a></li>
                                  <li><a href="/outerwear">OUTERWEAR</a></li>
                                  {/* <li><a href="/knitwear">KNITWEAR</a></li> */}
                                  <li><a href="/denim">DENIM </a></li>
                                  <li><a href="/accessories">ACCESSORIES </a></li>
                                  <br/>
                                  <li><a href="/allitems">SHOP ALL </a></li>
                                </ul>
                              </li>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <li className="mega-menu-item"><a href="#" className="mega-item-title">THERESA COLLECTION</a>
                                <ul>
                                  <li><a href="/theresafilm">THERESA FILM</a></li>
                                  <li><a href="/theresalookbook">THERESA LOOKBOOK</a></li>
                                  <li><a href="/theresa">THERESA CAMPAIGN</a></li>
                                </ul>
                                  <br/>
                                  <br/>
                                <ul>
                                </ul>
                              </li>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                              <li className="mega-menu-item"><a href="#" className="mega-item-title">COLLECTIONS</a>
                                <ul>
                                <li><a href="/wilderness_pt2">WILDERNESS PT2 <small>THE OTHER SIDE</small></a></li>
                                <li><a href="/wilderness">WILDERNESS</a></li>
                                  <li><a href="/divinemercy">DIVINE MERCY</a></li>
                                  <li><a href="/lookbook">YOUTH ENERGY</a></li>
                                </ul>
                                <br/>
                                <br/>
                                <ul>
                                </ul>
                                </li>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              
                              <li className="mega-menu-item"><a href="#" className="mega-item-title">PAGES</a>
                                <ul>
                                <li><a href="/aboutdivinemercy">ABOUT US</a></li>
                                  <li><a href="/press">PRESS</a></li>
                                  <li><a href="/contact">CONTACT US</a></li>
                                  <br/>
                                  <br/>
                                </ul>
                              </li>
                            </ul>
                          </li>
                      </div>
                  )}
                <div className="header-mainmenu-area  d-lg-block">
                  <center>
                    <a href="/"><img className='logowidth' src="assets/img/img/logo2.png" width={80} alt="Logo" /></a>
                  </center>
                </div>
                <div className="header-right-area d-flex justify-content-end align-items-center leftmargin">
                <div className="float-icons">
                {isMobile? (
                  <a data-mfp-src="#search-box-popup" class="search-icon animate-modal-popup" href='#' style={{ color: 'black', fontSize: '20px' }}>
                  <small>
                    <i className='pe pe-7s-search'></i>
                  </small>
                </a>
                ) : (
                  <a href='/register' style={{ color: 'black', marginRight: '10px',fontSize: '13px' }}>
                  <small>ACCOUNT</small>
                </a>
                 
                )}
                  </div>
                  <div className="float-icons">
                  {isMobile? (
                    <a href='/register' style={{color: 'black', fontSize: '20px'}}>  <small><i className='pe pe-7s-user'></i></small></a>
                   // <a href='https://oyeleke.com/allitems#!/~/account' style={{color: 'black', fontSize: '20px'}}>  <small><i className='pe pe-7s-user'></i></small></a>
                ) : (
                  // <a href='/account' style={{color: 'black',fontSize: '13px'}}> <small>ACCOUNT</small></a>
                  <a class="search-icon animate-modal-popup" data-mfp-src="#search-box-popup" href='#' style={{ color: 'black', marginRight: '10px',fontSize: '13px' }}>
                  <small>SEARCH</small>
                </a>
                )}
                  </div>
                  <div className="ec-cart-widget float-icons" style={{color: 'black', width: '70% !important'}}>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          <div class="modalSearchBox" id="search-box-popup">
            <div class="modaloverlay"></div>
            <div class="search-box-wrapper">
                <p>Start typing and press Enter to search</p>
                <div class="search-box-form">
                    <form action="#" class="search-form-area">
                        <input type="text" name="search" value={state.search} onChange={handleChange} id="search" placeholder="Search Oyeleke store"/>
                        <button onClick={handleSearch} id={state.search} class="btn-search"><i class="dl-icon-search10"></i></button>
                    </form>
                </div>
            </div>
        </div>
      </div>
      </header>
  );
}

export default Header;
