import React from 'react';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import './film.css';
import { Link } from 'react-router-dom'; 



function Wildernessfilm(){

      return (
              <div>
     <Header/>
     <br/>
     <br/>
    
     <br/> 
     <br/>
     <a href='#' style={{color: 'black'}}>. </a> 
     <center style={{marginLeft: '-3%'}}> <p> <a href='/wildernessfilm' style={{color: 'white', fontWeight: '800'}}> FILM </a> &nbsp;&nbsp;|&nbsp; <a style={{color: 'white'}} href='/wilderness'> CAMPAIGN </a> &nbsp;&nbsp;|&nbsp;  <a style={{color: 'white'}} href='/wildernesslookboook'> LOOKBOOK</a> &nbsp;&nbsp;&nbsp; </p></center>
     <br/>
     <br/>
     <br/> 
     <br/>
   



     <section className="wrapper" style={{ height: '100vh' }}>
      <div className="container">
        <style>
          {`
            /* Add this CSS within a <style> tag in the section */
            .wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .container {
              width: 100%;
              height: 100%;
              overflow: hidden;
            }

            #filmvid {
              object-fit: cover;
              width: 100%;
              height: 109%;
              position: absolute;
              top: 0px;
              left: 0;
              object-fit: cover;
              object-position: center 20%; /* Adjust the vertical position (10% down from the top) */
              z-index: -1;
            }
          `}
        </style>
        <video
          name="vid"
          id="filmvid"
          width="100%"
          height="100%"
          playsInline
          autoPlay
          loop
          muted
        >
          <source src="assets/media/Wilderness.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
     
        {/* <div id="about-page-wrapper" className="pt-90 pt-md-60 pt-sm-50 pb-50 pb-md-20 pb-sm-10">
          <section className="wrapper col-sm-12 col-md-12 col-lg-12" style={{height: '100vh'}}>
            <br />
            <br />
            <div className="container col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <video name="vid" id="filmvid" width="50%" height="70%" playsInline autoPlay loop muted>
                <source src="assets/media/Wilderness.mp4" type="video/mp4" />
              </video>
            </div>
          </section>
        </div> */}
     <Footer/>
              </div>
               
        );
 
  };

export default Wildernessfilm;
