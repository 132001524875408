import React, { useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import './mystyle.css';

function AllItems() {
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://app.ecwid.com/script.js?31093036&data_platform=code&data_date=2020-06-03';
    script1.type = 'text/javascript';
    script1.async = true;
    document.head.appendChild(script1);

    // Load the Ecwid store using the script
    script1.onload = () => {
      const script2 = document.createElement('script');
      script2.type = 'text/javascript';
      script2.innerHTML = `xProductBrowser("categoriesPerRow=3","views=grid(20,3) list(60) table(60)","categoryView=grid","searchView=list","id=my-store-31093036","defaultCategoryId=160723502");`;
      // script2.innerHTML = `xProductBrowser("categoriesPerRow=3","views=grid(20,3) list(60) table(60)","categoryView=grid","searchView=list","id=my-store-31093036","defaultCategoryId=160723502");`;
      // Replace YOUR_ALL_ITEMS_CATEGORY_ID with the actual category ID for "All Items"
      document.head.appendChild(script2);
      const ecwidContainer = document.getElementById('my-store-31093036');
      if (ecwidContainer) {
        ecwidContainer.style.fontFamily = 'Poppins, sans-serif'; // Replace with your desired font
      }
    };
  }, []);

  return (
    <div>
      <Header />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div id="blog-page-wrapper" className="pt-90 pt-md-60 pt-sm-50 pb-48 pb-md-20 pb-sm-10">
        <div className="container">
          <div id="my-store-31093036"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AllItems;
