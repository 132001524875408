import React from 'react';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import './film.css';



function Theresafilm(){

  const film = (e) => {
    window.location.href = 'lookbook'
  }
      return (
              <div>
     <Header/>
     <br/>
     <br/>
     <br/> 
     <br/>
     <a href='#' style={{color: 'black'}}>. </a> 
     <center style={{marginLeft: '-3%'}}> <p> <a href='/theresafilm' style={{color: 'black', fontWeight: '900'}}> FILM </a> &nbsp;&nbsp;|&nbsp; <a onClick={film} style={{color: 'black'}} href='/theresa'> CAMPAIGN </a> &nbsp;&nbsp;|&nbsp;  <a style={{color: 'black'}} href='/theresalookbook'> LOOKBOOK</a> &nbsp;&nbsp;&nbsp; </p></center>
     <br/>
     <br/>
     <br/>
     <section className="wrapper" style={{ height: '100vh' }}>
      <div className="container">
        <style>
          {`
            /* Add this CSS within a <style> tag in the section */
            .wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .container {
              width: 100%;
              height: 100%;
              overflow: hidden;
            }

            #filmvid {
              object-fit: cover;
              width: 100%;
              height: 109%;
              position: absolute;
              top: 0;
              left: 0;
              object-fit: cover;
              object-position: center 20%; /* Adjust the vertical position (10% down from the top) */
              z-index: -1;
            }
          `}
        </style>
        <video
          name="vid"
          id="filmvid"
          width="100%"
          height="100%"
          playsInline
          autoPlay
          loop
          muted
        >
          <source src="assets/media/oyeleke4.mov" type="video/mp4" />
        </video>
      </div>
    </section>
     
     <Footer/>
              </div>
               
        );
 
  };

export default Theresafilm;
