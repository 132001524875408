import React, {useState, useEffect} from 'react';
import Header from './header'; 
import Footer from './footer'; 
import axios from "axios";


function Search(){
    const [searchResults, setSearchResults] = useState([]);
    const [state , setState] = useState({
        search: '',
        password: '',
      })

      const urlParams = new URLSearchParams(window.location.search);
      const searchvalue = urlParams.get('search');

    // const handleSearch = async () => {
    //   try {
    //     const storeId = '31093036';
    //     const apiKey = 'public_6QgPvTQPLapDmLqsmPVCzkqrdiVsywj5';
    //     const apiUrl = `https://app.ecwid.com/api/v3/31093036/products?token=public_6QgPvTQPLapDmLqsmPVCzkqrdiVsywj5&keyword=${searchvalue}`;
    //     console.log(state.search)
    //     const response = await axios.get(apiUrl);
    //     localStorage.setItem("show_ec_logs","true")
    //     // Extract relevant data from the API response
    //     const products = response.data.items || [];
    //     setSearchResults(products);
    //     console.log(products)
    //     console.log(response.data)
    //   } catch (error) {
    //     console.error('Error searching products:', error);
    //   }
    // };

    const handleSearch = async (e) => {
        const storeId = '31093036';
        const apiKey = 'public_6QgPvTQPLapDmLqsmPVCzkqrdiVsywj5';
        const apiUrl = `https://app.ecwid.com/api/v3/31093036/products?token=public_6QgPvTQPLapDmLqsmPVCzkqrdiVsywj5&keyword=${searchvalue}`;
        try{
        await axios.get(apiUrl)
        .then((response) => {
          console.log("response received: ", response);
          if(!response){
            console.log(`HTTP error! Status: ${response.status}`)
          }else{
          console.log("response received: ", response);
            localStorage.setItem("show_ec_logs","true");
            const products = response.data.items || [];
            setSearchResults(products);
            console.log(products)
            console.log(response.data)
          }
        });
        } catch (error) {
            console.error('Error searching products:', error);
        }
      };
  
    useEffect(() => {
        handleSearch();    
      }, []); 

   

   
      return (
              <div>
     <Header/>
      <div className="shop-page-products-wrapper mt-44 mt-sm-30 mypadding">
        <div className="products-wrapper products-on-column">
        <br/>
          <br/>
          <br/>
        <p style={{fontSize: '11px', fontWeight: '600'}}>Search Results for "{searchvalue}"</p>
          <div className="row ">
          {Array.isArray(searchResults) && searchResults.length > 0 ? (
          searchResults.map((product) => (
          <div className="col-lg-3 col-sm-6 ">
          <div className="single-product-wrap card">
            <figure className="product-thumbnail">
              <a href={product.url? product.url.replace('https://oyeleke.com', 'http://localhost:3000'):''} className="d-block"  target='_blank'>
                <img className="primary-thumb" src={product.thumbnailUrl} alt={product.name} />
                <img className="secondary-thumb" src={product.thumbnailUrl} alt={product.name} />
              </a>
            </figure>
            <div className="product-details">
              <h2 className="product-name" style={{fontSize: '11px', fontWeight: '600'}}><a href="single-product.html">{product.name}</a></h2>
              <div className="product-prices">
                <span className="price" style={{fontSize: '11px', fontWeight: '400', color: 'black'}}>${product.price}</span>
              </div>
              <div className="list-view-content">
                <p className="product-desc">{product.description}</p>
              </div>
            </div>
          </div>
          </div>
            ))
            ): (
                <p>No Items matched your search</p>
            )
            }
          </div>
          </div>
          </div>
           <Footer/>
              </div>
               
        );
 
  };

export default Search;
