import React from 'react';
import Header from './header'; 
import Footer from './footer';
import './mystyle.css';


function Terms(){
      return (
              <div>
     <Header/>
     <br/>
     <br/>
     <br/> 
     <br/>
        <div id="blog-page-wrapper" className="pt-90 pt-md-60 pt-sm-50 pb-48 pb-md-20 pb-sm-10">
          <div className="container">
            <div className="blog-page-post-wrap">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="single-blog-post-wrap">
                    <p style={{textAlign: 'justify', lineHeight: '2.0',}}>
                    <h4>TERMS OF USE</h4>
                    <br/>
                    <h5>EFFECTIVE DATE:  DECEMBER 9, 2023</h5>
                    <br/>
                    <h5> LAST UPDATED:  DECEMBER 18, 2023</h5>
                    <br/>
                    WELCOME TO OYELEKE.COM (THIS “WEBSITE” OR “SITE”), A WEBSITE OWNED AND OPERATED BY OYELEKE, 
                    LLC (“OYELEKE,” “WE,” OR “US”). THESE TERMS OF USE AND THE PRIVACY POLICY APPLY TO ALL VISITORS, 
                    USERS, AND OTHERS WHO ACCESS THIS WEBSITE (“USERS” AND/OR “YOU”). THESE TERMS OF USE EXPLAIN THE TERMS 
                    BY WHICH YOU MAY USE OUR WEBSITE AND OUR SERVICES. BY ACCESSING OR USING THE WEBSITE, YOU SIGNIFY THAT YOU HAVE READ, 
                    UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS OF USE (“TERMS OF USE”), WHETHER OR NOT YOU HAVE REGISTERED TO CREATE
                     AN ACCOUNT [HTTPS://OYELEKE.COM/PRIVACY].  THESE TERMS OF USE AND OUR PRIVACY POLICY 
                     [HTTPS://OYELEKE.COM/PRIVACY] CREATE THE TERMS OF YOUR AND OUR AGREEMENT WHENEVER YOU USE THIS WEBSITE, 
                    AND WHEN YOU CHOOSE TO PURCHASE PRODUCTS THROUGH THIS WEBSITE.
                    <br/>
                    <br/>
                    PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE YOU START TO USE THIS WEBSITE. BY ACCESSING OR USING THIS WEBSITE AND/OR PURCHASING PRODUCTS THROUGH OUR WEBSITE, 
                    YOU AGREE TO BE BOUND AND ABIDE BY THESE TERMS OF USE AND OUR PRIVACY POLICY FOUND AT HTTPS://OYELEKE.COM/PRIVACY. 
                    IF YOU DO NOT AGREE TO THESE TERMS OF USE OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THIS SITE.
                    <br/>
                    <br/>
                    THIS PRIVACY POLICY DOES NOT APPLY TO PERSONAL INFORMATION THAT WE COLLECT IN CONNECTION WITH YOUR WORK FOR US AS AN EMPLOYEE OR INDEPENDENT CONTRACTOR. 
                     THAT INFORMATION IS GOVERNED BY OUR WORKFORCE PRIVACY POLICY.
                    <br/>
                    <br/>
                    PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND OUR POLICIES AND PRACTICES.  BY USING THE SITE AND SERVICES OR PROVIDING YOUR PERSONAL INFORMATION TO US, 
                    YOU CONSENT TO THE USE OF YOUR INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY.  IF YOU DO NOT AGREE WITH ANY TERM IN THIS PRIVACY POLICY,
                    YOU SHOULD NOT ACCESS AND USE THE SITES AND PLEASE DO NOT OTHERWISE PROVIDE US WITH ANY PERSONAL INFORMATION.
                     <br/>
                     <br/>
                     IF YOU ARE NOT AT LEAST 13 YEARS OLD, DO NOT USE THIS WEBSITE. IF YOU ARE BETWEEN THE AGES OF 13 AND 18, 
                     WE ASSUME THAT IF YOU ARE USING THIS SITE OR PURCHASING PRODUCTS THROUGH THIS SITE THAT YOU HAVE THE PERMISSION 
                     OF A PARENT OR GUARDIAN TO DO SO AND IF YOU DO NOT THEN DO NOT USE THIS SITE OR PURCHASE PRODUCTS ON THIS SITE.
                     <br/>
                     <br/>
                     <b>CHANGES AND UPDATES</b>
                        <br/>
                        <p style={{textTransform: 'uppercase',lineHeight: '2.0'}}>
                        WE RESERVE THE RIGHT TO AMEND THESE TERMS OF USE AND PRIVACY POLICY AT ANY TIME AND WITHOUT PRIOR NOTICE. IF WE DO THIS, WE WILL POST THE AMENDED TERMS OF USE AND/OR PRIVACY POLICY AND INDICATE AT THE TOP OF THE TERMS OF USE OR THE PRIVACY POLICY, AS THE CASE MAY BE, THE DATE SUCH WAS LAST REVISED. YOUR CONTINUED USE OF THIS WEBSITE AFTER ANY SUCH CHANGES ARE MADE MEANS YOU ACCEPTANCE OF THE NEW TERMS OF USE AND PRIVACY POLICY. IF YOU DO NOT AGREE TO ANY CHANGES OR ADDITIONS TO THESE TERMS OF USE, DO NOT CONTINUE TO USE OR ACCESS THIS SITE. 
                        <br/> 
                        <br/>
                       <b> ACCESSING THIS WEBSITE AND ACCOUNT SECURITY:</b>
                       <br/>
                       WE RESERVE THE RIGHT TO WITHDRAW OR AMEND ANY MATERIAL WE PROVIDE ON THIS WEBSITE IN OUR SOLE DISCRETION. WE WILL NOT BE LIABLE IF FOR ANY REASON ALL OR ANY PART OF THIS WEBSITE IS UNAVAILABLE AT ANY TIME OR FOR ANY PERIOD. FROM TIME TO TIME, WE MAY RESTRICT ACCESS TO SOME PARTS OF THIS WEBSITE, OR THE ENTIRE WEBSITE, TO USERS, INCLUDING ANY REGISTERED USERS.
                        <br/>
                        <br/>
                       IF YOU REGISTER FOR AN ACCOUNT, YOU WILL BE ASKED TO PROVIDE TO US CERTAIN PERSONAL INFORMATION AT THE POINT OF COLLECTION AND TO CREATE A PASSWORD (SEE OUR PRIVACY POLICY FOR MORE INFORMATION)  IF YOU THINK SOMEONE ELSE HAS COMPROMISED YOUR ACCOUNT OR TAKEN YOUR PASSWORD WITHOUT PERMISSION, PLEASE ALERT US IMMEDIATELY AT [INFO@OYELEKE.COM].  IF WE THINK YOU OR SOMEONE ELSE IS USING THIS WEBSITE OR YOUR ACCOUNT IN A WAY NOT ALLOWED BY THESE TERMS OF USE, WE HAVE THE RIGHT TO DISABLE YOUR ACCOUNT OR OTHER CREDENTIALS YOU SET UP AT ANY TIME.
                        <br/>
                       <br/>
                       <b>USE OF YOUR ACCOUNT</b>
                       <br/>
                       YOU ARE RESPONSIBLE FOR ALL ACTIVITY ON THE SITE FROM YOUR ACCOUNT OR THAT USE YOUR CREDENTIALS. YOU ARE RESPONSIBLE AND LIABLE FOR ANY SECURITY BREACHES THAT OCCUR WITH RESPECT TO THE WEBSITE THROUGH YOUR ACCOUNT.
                        <br/>
                       <br/>
                        <b>USE OF OUR WEBSITE</b>
                       <br/>
                       YOU AGREE NOT TO USE OR LAUNCH ANY AUTOMATED SYSTEM, INCLUDING WITHOUT LIMITATION, “ROBOTS,” “SPIDERS,” “OFFLINE READERS,” OR ANY OTHER TOOL OR MALICIOUS CODE THAT WOULD DISRUPT OUR WEBSITE OR TRY TO COMPROMISE OTHER USERS AND OTHER ACCOUNTS.  THIS SITE DOES NOT ALLOW USERS TO POST CONTENT.
                        <br/>
                        YOU AGREE THAT YOU WILL NOT USE ANY MEANS WITH RESPECT TO THE WEBSITE INCLUDING ANY AUTOMATIC OR ELECTRONIC MEANS, TO OBTAIN OR ACCESS ANY INFORMATION, DATA OR MATERIALS PROVIDED BY ANY OTHER USERS.
                       <br/>
                       <br/>
                       <b>SHOPPING</b>
                       <br/>  
                       <b>SHOPPING BAGS</b>
                       <br/>  
                       WHEN YOU SHOP ON OUR SITE, YOU CAN USE A SHOPPING BAG TO KEEP TRACK OF THE ITEMS YOU HAVE SELECTED UNTIL YOU ARE DONE SHOPPING.  YOU WILL BE ABLE TO REVIEW AND CONFIRM YOUR CHOICES, YOUR SHIPPING ADDRESS, HOW YOU ARE PAYING FOR YOUR ITEMS, AND THE SIZES YOU NEED.
                        <br/>
                        <br/>
                        <b>CHECK OUT</b>
                        <br/>
                        WHEN YOU CHECK OUT, THE DATA IS TRANSMITTED TO OUR THIRD PARTY PAYMENT PROCESSING COMPANY (AT PRESENT, WE USE ECWID).  SHOPIFY STORES YOUR PAYMENT INFORMATION ON OUR BEHALF.  IF YOU USE OTHER MEANS OF PAYMENT (SUCH AS APPLEPAY), YOU WILL BE ENTERING YOUR INFORMATION INTO THOSE THIRD PARTIES’ SITES, AND YOU SHOULD REVIEW THOSE PROVIDERS’ TERMS OF USE AND PRIVACY POLICIES.
                        <br/>
                        <br/>
                        <b>PRICING AND PRODUCT ERRORS/CORRECTIONS</b>
                        <br/>
                        WE ENDEAVOR TO PRESENT CURRENT, ACCURATE, AND RELIABLE INFORMATION REGARDING OUR PRODUCTS.  HOWEVER, ON OCCASION, THIS SITE MAY CONTAIN TYPOGRAPHICAL ERRORS, INACCURACIES, OR OMISSIONS THAT MAY BE WITH REGARD TO PRODUCT DESCRIPTIONS, PRICING, PROMOTIONS, OFFERS, AND AVAILABILITY. WE RESERVE THE RIGHT TO CORRECT ERRORS OR TO UPDATE PRODUCT INFORMATION AT ANY TIME WITHOUT PRIOR NOTICE. IF A PRODUCT IS LISTED AT AN INCORRECT PRICE DUE TO AN ERROR ON THIS SITE, TO THE FULLEST EXTENT ALLOWED BY LAW, WE RESERVE THE RIGHT TO REFUSE OR CANCEL ANY ORDERS PLACED FOR ANY PRODUCT LISTED AT THE INCORRECT PRICE.  FURTHER, TO THE FULLEST EXTENT ALLOWED BY LAW, WE RESERVE THE RIGHT TO REFUSE OR CANCEL ANY SUCH ORDERS, REGARDLESS OF WHETHER THE ORDER HAS BEEN CONFIRMED AND YOUR METHOD OF PAYMENT CHARGED. IF YOUR METHOD OF PAYMENT HAS ALREADY BEEN CHARGED FOR THE PURCHASE AND YOUR ORDER IS THEN CANCELLED, WE WILL ISSUE A CREDIT TO YOUR ORIGINAL METHOD OF PAYMENT FOR THE AMOUNT OF THE INCORRECT PRICE.
                        <br/>
                        <br/>
                        <b>NOT FOR RESALE</b>
                            <br/>
                            YOU ARE EXPRESSLY PROHIBITED FROM USING THIS SITE TO PURCHASE ANY PRODUCT FOR PURPOSES
                             OF RESALE BY YOU OR ANY OTHER PERSON, AND WE EXPRESSLY DISCLAIM ALL WARRANTY FOR CLAIMS FOR
                              DAMAGES OR OTHERWISE, INCLUDING ALL INDIRECT OR CONSEQUENTIAL DAMAGES RESULTING FROM YOUR INTENT 
                              TO, ATTEMPT TO, OR ACTUAL RESELL ANY PRODUCT PURCHASED FROM THE WEBSITE.
                            <br/>
                            <br/>
                        <b>FRAUD</b>
                             <br/>
                             IF THERE IS ANY CONCERN ABOUT FRAUD OR CREDIT, WE WILL LET YOU KNOW IF WE ARE ALLOWED BY LAW.  WE MAY BE REQUIRED BY LAW TO FIRST REPORT THE PROBLEM TO LAW ENFORCEMENT OFFICIALS.  WE USE A THIRD PARTY VENDOR TO DETECT FRAUD.
                                <br/>
                                <br/>
                            <b>PROPRIETARY RIGHTS</b>
                            <br/>
                            THIS WEBSITE AND ITS ENTIRE CONTENTS, OUR PRODUCTS, AND THE PHOTOGRAPHS OF OUR MODELS AND OUR PRODUCTS 
                            (INCLUDING BUT NOT LIMITED TO ALL INFORMATION, TRADEMARKS, MARKS, SERVICE MARKS, DISPLAYS, IMAGES, VIDEO AND AUDIO AND THE DESIGN, SELECTION, AND ARRANGEMENT THEREOF) 
                            (COLLECTIVELY AND INDIVIDUALLY, THE “MATERIALS”) ARE OWNED BY OYELEKE, ITS LICENSORS OR OTHER PROVIDERS OF SUCH MATERIAL AND ARE PROTECTED BY UNITED STATES AND INTERNATIONAL COPYRIGHT,
                             TRADEMARK, PATENT, TRADE SECRET, AND/OR OTHER INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS LAWS.  YOU PROMISE AND AGREE NOT TO SELL, LICENSE, RENT, MODIFY, DISTRIBUTE, COPY, REPRODUCE, TRANSMIT, PUBLICLY DISPLAY, 
                             PUBLICLY PERFORM, PUBLISH, ADAPT, EDIT OR CREATE DERIVATIVE WORKS FROM ANY OYELEKE MATERIALS. 
                            USE OF THE OYELEKE MATERIALS FOR ANY PURPOSE NOT EXPRESSLY PERMITTED BY THESE TERMS OF USE IS STRICTLY PROHIBITED.
                            <br/>
                            YOU MAY CHOOSE TO SUBMIT TO US COMMENTS OR SUGGESTIONS ABOUT THE SITE AND OUR PRODUCTS.
                             BY SUBMITTING A COMMENT OR SUGGESTION, YOU UNDERSTAND AND AGREE THAT WE HAVE THE UNLIMITED RIGHT TO USE THOSE COMMENTS OR SUGGESTIONS FOR ANY PURPOSE,
                             WITHOUT ANY COMPENSATION OR ATTRIBUTION TO YOU.
                             <b>SECURITY</b>
                             <br/>
                             WE HAVE IMPLEMENTED REASONABLE TECHNICAL, SECURITY AND ORGANIZATIONAL MEASURES DESIGNED TO SECURE YOUR PERSONAL INFORMATION FROM ACCIDENTAL 
                             LOSS AND FROM UNAUTHORIZED ACCESS, USE, ALTERATION OR DISCLOSURE.  FOR MORE INFORMATION, SEE OUR PRIVACY POLICY [HTTPS://OYELEKE.COM/PRIVACY].  
                             NOTWITHSTANDING THESE REASONABLE MEASURES, WE CANNOT GUARANTEE THAT SOMEONE WILL NOT BE ABLE TO ACCESS YOUR INFORMATION WITHOUT OUR OR YOUR PERMISSION.  
                             IT IS YOUR CHOICE WHETHER OR NOT TO PROVIDE INFORMATION TO US THROUGH THIS WEBSITE. 
                            <br/>
                            <br/>
                            <b>SECURITY</b>
                             <br/>
                             WE HAVE IMPLEMENTED REASONABLE TECHNICAL, SECURITY AND ORGANIZATIONAL MEASURES DESIGNED TO SECURE YOUR PERSONAL INFORMATION FROM ACCIDENTAL 
                             LOSS AND FROM UNAUTHORIZED ACCESS, USE, ALTERATION OR DISCLOSURE.  FOR MORE INFORMATION, SEE OUR PRIVACY POLICY [HTTPS://OYELEKE.COM/PRIVACY]. 
                              NOTWITHSTANDING THESE REASONABLE MEASURES, WE CANNOT GUARANTEE THAT SOMEONE WILL NOT BE ABLE TO ACCESS YOUR INFORMATION WITHOUT OUR OR YOUR PERMISSION.  
                             IT IS YOUR CHOICE WHETHER OR NOT TO PROVIDE INFORMATION TO US THROUGH THIS WEBSITE. 
                             <br/>
                             <br/>
                             <b>THIRD-PARTY SERVICE PROVIDER.</b>
                             <br/>
                             WHEN YOU PROCEED TO CHECKOUT, YOUR INFORMATION WILL BE TRANSMITTED TO OUR THIRD PARTY PAYMENT PROCESSOR (AT PRESENT, SHOPIFY).  WE ALSO HAVE A THIRD PARTY THAT MONITORS OUR ECOMMERCE TRANSACTIONS TO DETECT FRAUD. 
                              SEE OUR PRIVACY POLICY FOR MORE INFORMATION ABOUT OUR THIRD PARTY PROVIDERS
                              <br/>
                              <br/>
                              <b>INDEMNITY</b>
                              <br/>
                              TO THE FULLEST EXTENT ALLOWED BY LAW, YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS 
                              OYELEKE AND ITS SUBSIDIARIES, AGENTS, MANAGERS, AND OTHER AFFILIATED COMPANIES, AND THEIR EMPLOYEES, CONTRACTORS, AGENTS, OFFICERS AND DIRECTORS, FROM AND AGAINST ANY AND ALL CLAIMS, DAMAGES, OBLIGATIONS, LOSSES, LIABILITIES, COSTS. DEBTS, AND EXPENSES (INCLUDING BUT NOT LIMITED TO ATTORNEY’S FEES) ARISING FROM: (I) YOUR USE OF AND ACCESS TO THE WEBSITE; (II) YOUR VIOLATION OF ANY TERM OF THESE TERMS OF USE, INCLUDING WITHOUT LIMITATION; (III) YOUR VIOLATION OF ANY THIRD-PARTY RIGHT, INCLUDING WITHOUT LIMITATION ANY RIGHT OF PRIVACY, INTELLECTUAL PROPERTY RIGHTS OR ANY CONTRACTUAL OR PROPRIETARY RIGHTS; (IV) YOUR VIOLATION OF ANY LAW, RULE OR REGULATION OF THE UNITED STATES OR ANY OTHER COUNTRY; 
                              (V) ANY CLAIM OR DAMAGES THAT ARISE AS A RESULT OF ANY INFORMATION OR DATA THAT ARE SUBMITTED VIA YOUR ACCOUNT; OR (VI) ANY OTHER PARTY’S ACCESS AND USE OF THE WEBSITE WITH YOUR UNIQUE USERNAME, PASSWORD OR OTHER APPROPRIATE SECURITY CODE.
                              <br/>
                              <br/>
                              <b>DISCLAIMERS AND LIMITATION OF LIABILITY</b>
                              <br/>
                              THIS WEBSITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. EXCEPT AS EXPRESSLY STATED HEREIN, THE SITE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, OYELEKE, ITS SUBSIDIARIES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT ON THIS WEBSITE IS ACCURATE, RELIABLE OR CORRECT; THAT THE SITE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SITE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.

                                TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL OYELEKE, ITS AFFILIATES AND THEIR OFFICERS, DIRECTORS, MEMBERS, MANAGERS, EMPLOYEES OR ITS LICENSORS, VENDORS OR SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, THAT ARISE FROM OR RELATE TO THE TERMS OF USE, THIS WEBSITE OR THAT RESULT FROM THE USE OF, OR INABILITY TO USE, THE WEBSITE.

                                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES WILL OYELEKE BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SITE OR YOUR ACCOUNT OR THE INFORMATION CONTAINED THEREIN.

                                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OYELEKE AND ITS AFFILIATES AND THEIR OFFICERS, DIRECTORS, MEMBERS, MANAGERS AND EMPLOYEES HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF  ANY CONTENT, MATERIALS, DATA OR INFORMATION; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR SITE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF THIS WEBSITE AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY BUGS, VIRUSES, TROJAN HORSES, MALICIOUS CODE OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR SITE BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT, INFORMATION OR DATA OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT, DATA OR INFORMATION POSTED, UPLOADED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SITE; AND/OR (VII) THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY.

                                IN NO EVENT SHALL OYELEKE AND ITS AFFILIATES AND THEIR OFFICERS,  DIRECTORS, EMPLOYEES, MEMBERS, MANAGERS OR OUR LICENSORS, SUPPLIERS OR VENDORS BE LIABLE TO ANY USER  IN THE AGGREGATE FOR ANY AND ALL CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS ARISING OUT OF OR RELATING TO THE TERMS OF USE, THE PRODUCTS OR THIS WEBSITE IN AN AMOUNT EXCEEDING THE AMOUNT, IF ANY, THAT SUCH USER HAS PAID TO OYELEKE FOR THE PRODUCTS PURCHASED THROUGH THIS SITE GIVING RISE TO THE CLAIM.

                                THESE LIMITATIONS OF LIABILITY APPLY WHETHER THE CLAIM OR ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF OYELEKE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.
                        
                        </p>
                    </p>
                   
                    
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

     <Footer/>
              </div>
               
        );
 
  };

export default Terms;
