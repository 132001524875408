import React from 'react';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import './mystyle.css';


function SingleBlog(){
      return (
              <div>
     <Header/>
     <br/>
     <br/>
     <br/> 
     <br/>
     <div className="page-header-wrapper layout-two" style={{backgroundImage: 'url(assets/img/img/DSC00823.jpg) !important', backgroundPosition: 'center'}}>
     <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="page-header-content">
                  <h2>PRESS</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="blog-page-wrapper" className="pt-90 pt-md-60 pt-sm-50 pb-48 pb-md-20 pb-sm-10">
          <div className="container">
            <div className="blog-page-post-wrap">
              <div className="row">
                {/* Start Single Blog Item */}
                <div className="col-md-12 col-lg-12">
                  <div className="single-blog-post-wrap">
                    <p style={{textAlign: 'justify'}}>
                      BOBBY TRENDZ, vibe curator and visual enthusiast, is now the Director of Public Relations at the newly developed 
                      clothing brand, OYELEKE. As Director, Bobby Trendz will be responsible
                      for leading outward strategies that plan, promote, and maximize key 
                      initiatives, image and reputation. Bobby Trendz will also establish
                      and nurture relationships with media and other influential 
                      professionals to expand OYELEKE to its optimal fruition.
                    </p>
                    <figure className="blog-post-thumbnail">
                      <a href="single-blog.html"><img src="assets/img/img/bobbytrendz.png" alt="Blog" /></a>
                    </figure>
                    <div className="blog-post-content">
                      <div className="blog-meta">
                        <a href="single-blog.html" className="author">By Bobola Oyeleke</a>
                        <span> - </span>
                        <a href="single-blog.html" className="date">August 14, 2020</a>
                        <span> / </span>
                      </div>
                      <h2><a href="single-blog.html">BOBBY TRENDZ NAMED DIRECTOR OF PUBLIC RELATIONS AT OYELEKE
                        </a></h2>
                      <p style={{textAlign: 'justify', fontStyle: 'italic'}}>“Bobby has been representing the brand unofficially from its inception. 
                        His experience with the brands he’s represented, and his visual eye are obvious, but it’s his ethical values and 
                        social consciousness That particularly align with the OYELEKE brand.
                        That’s why we are officially bringing him on as the head of PR. 
                        His understanding of luxury and savoir-faire would be useful in 
                        representing OYELEKE through the future.
                        ” -Bobola Oyeleke, Founder of OYELEKE</p>
                      <p style={{textAlign: 'justify'}}>
                        With close to a decade of experience under his fashionably forward belt, 
                        BOBBY TRENDZ has become an accredited creative director, 
                        wardrobe stylist, social media consultant, and public relations savant.
                        Having exhibited his proficiency in these areas with 
                        reputable names/brands like Wynton Harvey, June Ambrose, 
                        Salvatore Ferragamo, Neiman Marcus, and BYREDO, there’s no wonder 
                        why he was hired to spearhead the public relations department. 
                        He has previously hosted a series of Fashion Presentations as well 
                        as Creative E-Cyphers, providing a plethora of opportunities for 
                        marketing and networking. In 2018, Trendz successfully launched his 
                        titular brand BOBBY TRENDZ. It focuses on building, 
                        maintaining and elevating his clientele to prominence.
                      </p>
                      <p style={{textAlign: 'justify'}}>
                        <span style={{fontStyle: 'italic'}}>OYELEKE</span>, a self-proclaimed yet appropriately described 
                        “sophi-eccentric gentle avant-garde” brand was founded in 2018 by
                        Bobola Oyeleke. A Harvard Business School graduate born and raised 
                        in Nigeria he currently designs software technology infrastructure 
                        for Disney. Utilizing the finest materials with deconstructed 
                        traditional shapes and dimensions, Oyeleke aims to create new 
                        silhouettes for the modern man.
                      </p>
                      <p style={{textAlign: 'justify'}}>
                        “It was 2018, sitting in his DTLA dining room,
                        when Bobola first introduced me to his vision 
                        for OYELEKE. I’d never heard someone speak with
                        so much fervor and passion. In addition to Bob’s 
                        drive, his immaculate cut and sew production 
                        coupled with his very high level of excellence 
                        and perfection, I left assured this would be a 
                        brand I’d want to unite with really soon... And 
                        now here we are, One!”
                        -Bobby Trendz
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

     <Footer/>
              </div>
               
        );
 
  };

export default SingleBlog;
