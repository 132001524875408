import React, {useState}  from 'react';
import Header from './header'; 
import Footer from './footer'; 
import './mystyle.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
// import { EcwidApi } from 'ecwid';


function Account(){
  const MySwal = withReactContent(Swal)
    const baseUrl = "http://localhost:6998/api/loginCustomer";
    const [state , setState] = useState({
        email: '',
        password: ''
      })
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };
     
      const handleChange = (e) => {
          const {id , value} = e.target   
          setState(prevState => ({
              ...prevState,
              [id] : value
          }))
      }


    const loginData = {
      email: state.email,
      password: state.password,
      };

      const loginUser = (e) => {
        if (!state.email || !state.password){
          MySwal.fire({
            title: <p>warning</p>,
            text: 'Please fill all required fields',
            icon: 'warning',
            confirmButtonColor: '#000000',
            didOpen: () => {
            },
          }).then(() => {})
        }else{
          MySwal.fire({
            title: <p>please wait...</p>,
            didOpen: () => {
              MySwal.showLoading()
              axios.post(baseUrl, loginData, axiosConfig)
            .then((response) => {
              console.log("response received: ", response);
                Swal.close()
                Swal.fire({
                    title: response.data.data,
                    text: response.data.message,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonText: "Okay",
                   confirmButtonColor: '#000000',
                    closeOnCancel: true,
                  }).then(result => {
                    console.log("response received: ", response);
                    sessionStorage.setItem("oyeleke_tokenize", JSON.stringify(response.data.accessToken));
                    sessionStorage.setItem("oyeleke_fname", JSON.stringify(response.data.user.firstname));
                    sessionStorage.setItem("oyeleke_id", JSON.stringify(response.data.user.id));
                    sessionStorage.setItem("oyeleke_ecwid_id", JSON.stringify(response.data.user.id));
                   window.location.href="https://oyeleke.com/allitems#!/~/account";
                  })
              })
              .catch((err) => {
                Swal.close()
                console.log("AXIOS ERROR: ", err);
                if (err.code == 'ERR_NETWORK'){
                  MySwal.fire({
                    title: 'Failed',
                     icon: 'warning',
                     confirmButtonColor: '#000000',
                   text: err.message,
                  //  text: err.message,
                   didOpen: () => {},
                 }).then(() => {
                  // window.location.reload()
                 });
                }
                else if (err.response.data.error.errorMessage){
                  MySwal.fire({
                    title: 'Failed',
                     icon: 'warning',
                     confirmButtonColor: '#000000',
                   text: err.response.data.error.errorMessage,
                  //  text: err.message,
                   didOpen: () => {},
                 }).then(() => {
                  // window.location.reload()
                 });
                } else {
                  MySwal.fire({
                    title: 'Failed',
                     icon: 'warning',
                     confirmButtonColor: '#000000',
                   text: err.message,
                  //  text: err.message,
                   didOpen: () => {},
                 }).then(() => {
                  // window.location.reload()
                 });
                }
              })
            },
          }).then(() => {
          })
        }
      }
      

      const handleLogin = () => {
        loginUser(state.email, state.password);
      };
      
      return (
              <div>
     <Header/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
        <div id="my-account-page-wrapper" className="pt-88 pt-md-58 pt-sm-48 pb-50 pb-md-20 pb-sm-10 putmarginleft">
          <div className="container">
            <div className="row">
            <div className="col-md-6">
                <div className="my-account-item-wrapper">
                  <h3>Login</h3>
                  <div className="my-account-form-wrap">
                    <form action="#" method="post">
                      <div className="single-form-input">
                        <label htmlFor="login_username">Email Address <sup>*</sup></label>
                        <input style={{height:'5px'}} type="text" id="email" onChange={handleChange} value={state.email} name='email' required />
                      </div>
                      <div className="single-form-input">
                        <label htmlFor="login_pwsd">Password <sup>*</sup></label>
                        <input style={{height:'5px'}} type="password" id="password" onChange={handleChange} value={state.password} name='password' required />
                      </div>
                      <div className="single-form-input d-flex align-items-center mb-14">
                        <button onClick={handleLogin} className="btn btn-black btn-sm" type="button">Verify Account</button>&nbsp;&nbsp;&nbsp;
                        <div className="lost-pswd">
                        <a href="/register">Register an Account</a>
                      </div>
                      </div>
                      {/* <div className="lost-pswd">
                        <a href="#">Lost your Password?</a>
                      </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         <Footer/>
              </div>
               
        );
 
  };

export default Account;
