import React from 'react';
import Header from './header'; 
import Footer from './footer'; 
import './mystyle.css';


function About(){
      return (
              <div>
     <Header/>
     <br/>
     <br/>
     <br/> 
     <br/>
     <div className="page-header-wrapper layout-two" style={{backgroundImage: 'url(../img/img/DSC00823.jpg) !important', backgroundPosition: 'center'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="page-header-content">
                  <h2>DIVINE MERCY</h2>
                  <nav className="page-breadcrumb">
                    <ul className="d-flex justify-content-center">
                      <li><a href="aboutdivinemercy">DIVINE MERCY</a></li>
                      <li><a href="aboutus">YOUTH ENERGY</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="about-page-wrapper" className="pt-90 pt-md-60 pt-sm-50 pb-50 pb-md-20 pb-sm-10">
          <div className="about-content-wrap mb-84 mb-md-54 mb-sm-46">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 mb-sm-28 mb-md-24">
                  <div className="about-thumbnail">
                    <img src="assets/img/img/divinemercy.jpeg" alt="About Thumbnail" />
                  </div>
                </div>
                <div className="col-lg-6 ms-auto my-auto">
                  <div className="about-content">
                    <h2>“OYEᒪEKE |Divine Mercy 21”</h2>
                    <p>Jesus appeared to St Faustina in a white garment with red and pale rays emanating from his heart. Red for the blood of Jesus, and pale for the water (which justifies souls) In her diary (Notebook I, Items 47 and 48), she wrote that Jesus told her: Paint an image according to the pattern you see, with the signature: "Jesus, I trust in You" (in Polish: "Jezu, ufam Tobie") I desire that this image be venerated, first in your chapel, and then throughout the world. I promise that the soul that will venerate this image will not perish.[14][15] The rays depicted in the image of divine mercy are referenced alot in the collection. The rays coming from the heart It was very important for the rays to come from the heart signify the oasis of mercy as it gushes out of the heart of Jesus.</p>
                    <p>Shortly after arriving Vilnius, St Faustina met Michael Sopocko, the new appointed confessor to the nuns. Sopocko became her spiritual director and his role was important in bringing the image to life. As a priest, Sopocko wore alot of satin cassoks and silk shawls. The satin dress in the collection references the catholic cassock, very identical to the one sopocko wore.The silk shawls are very identical to the surpilces worn by Sopocko. The same long shawl referenced on the dress is also also referenced in the tan coat. The long drawstrings referenced in the collection are identical to the extra length woven strings the priests tied their cassocks before mass. The priests wore it with so much class and grace, i wanted to keep that same feel on all the bottoms from the collection by extending the length on all bottoms. Reminiscing on the 7 years I served the altar and 3 years in the seminary. I used the same surplice as the ones I wore as an alter boy on the hoodie that i wore to close the show.</p>
                    <p>With this collection I have not only fulfilled the wish of St Faustina as instructed by Jesus Christ but I got to relive my seminarian days by wearing the hoodie with the silk surplice. Divine Mercy is a prayer, a symbol of charity, forgiveness and love of God remembrance of God's unending mercy and love, its a task, one that comes with promises. “I promise that the soul that will venerate this image (of Divine Mercy) will not perish. I also promise victory over (its) enemies already here on earth, especially at the hour of death. I Myself will defend it as My own glory.” (Diary, 48).</p>
                    {/* <p>The full list of the promises attached to divine mercy are here: https://fathersofmercy.com/promises- attached-to-chaplet-of-divine-mercy/ I will come back to Divine Mercy in future collections, to preach and remind us all of God's unending love and mercy. It's so important and might in-fact, be the only hope of saving the human race.</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     <Footer/>
              </div>
               
        );
 
  };

export default About;
