// Header.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// import CookieConsent from "react-cookie-consent";


function Footer() {

  const location = useLocation();
  const isCurrentPage = (path) => {
    // Check if the current route matches the provided path
    return location.pathname === path;
  };


  return (
    <div>
    <footer id="footer-area">
      {/* Start Footer Widget Area */}
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      {/* <CookieConsent
        location="bottom"
        buttonText="Accept All"
        cookieName="oyeleke"
        enableDeclineButton
        declineButtonText="Reject All"
        onDecline={() => {
          alert("nay!");
        }}
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent> */}

      <div className="footer-widget-area pt-40 pb-28">
        <div className="container">
          <div className="footer-widget-content">
            <div className="row">
              <div className="col-md-4 col-lg-3 order-2 order-lg-1">
                <div className="footer-widget-item-wrap">
                  <h3 className="widget-title">Contact Us</h3>
                  <div className="widget-body">
                    <div className="contact-text">
                      <a href="#">contact@oyeleke.com</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-6 order-1 text-start text-lg-center">
                <div className="row">
                  <div className="col-lg-8 col-xl-6 m-auto">
                    <div className="footer-widget-item-wrap">
                      <div className="widget-body">
                        <div className="about-text">
                          <a href="/"><img src="assets/img/img/logo2.png" alt="Logo" width={100} /></a>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 order-3">
                <div className="footer-widget-item-wrap">
                  <h3 className="widget-title">Join Our Newsletter</h3>
                  <div className="widget-body">
                    <div className="newsletter-area">
                      <form action="https://company.us19.list-manage.com/subscribe/post?u=2f2631cacbe4767192d339ef2&id=24db23e68a" method="post" id="mc-form" className="mc-form">
                        <input type="email" id="mc-email" autoComplete="off" placeholder="Enter Email Address" required />
                        <button className="btn" type="submit">subscribe</button>
                      </form>
                     
                      <div className="mailchimp-alerts">
                        <div className="mailchimp-submitting" />
                        <div className="mailchimp-success" />
                        <div className="mailchimp-error" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="footer-bottom-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-lg-5 m-auto order-1">
              <div className="footer-menu mb-sm-12">
                <ul className="nav justify-content-center justify-content-md-start">
                  <li><a href="/shipping">SHIPPING INFO</a></li>
                  <li><a href="/terms">TERMS OF SERVICE</a></li>
                  <li><a href="/privacy">PRIVACY POLICY</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 m-auto text-center text-md-start order-3 order-md-2">
              <div className="copyright-text mt-sm-10">
                <p>©
                  OYELEKE</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div className="mfp-hide modalLeftOffside" id="left-offside-popup" style={{width: '100%'}}>
      <div className="leftoffside-content-wrap">
        <nav className="offside-menu">
          <ul className="left-offsidemenu">
            {/* <li><Link to="/all" className={isCurrentPage('/all') ? 'actively' : ''}>SHOP</Link> */}
            <li className="menu-icon-bar modalActive" data-mfp-src="#left-offside-popup2">
                      SHOP  <i className='pe pe-7s-angle-right' style={{fontSize: '40px', float: 'right'}}></i> 
            </li>
            <li className="menu-icon-bar modalActive"  data-mfp-src="#left-offside-popup3">
              COLLECTIONS
            <i className='pe pe-7s-angle-right' style={{fontSize: '40px', float: 'right'}}></i> 
            </li>
            <li>
            {/* <button className="menu-icon-bar modalActive" data-mfp-src="#left-offside-popup2">
                      PRESS
                      </button> */}
            </li>
            <li><Link to="/press" className={isCurrentPage('/press') ? 'actively' : ''} data-mfp-src="#left-offside-popup2">PRESS</Link></li>
            <li><Link to="/aboutdivinemercy" className={isCurrentPage('/aboutdivinemercy') ? 'actively' : ''}>ABOUT US</Link></li>
            <li><Link to="/contact" className={isCurrentPage('/contact') ? 'actively' : ''}>CONTACT US</Link></li>
          </ul>
        </nav>
        <div className="offside-text">
          <a href="#">contact@oyeleke.com</a>
        </div>
      </div>
    </div>

    <div className="mfp-hide modalLeftOffside" id="left-offside-popup2" style={{width: '100%'}}>
      <div className="leftoffside-content-wrap">
      <i className='pe pe-7s-angle-left-circle modalActive' style={{fontSize: '40px'}} data-mfp-src="#left-offside-popup"></i>
      <br/>
      <br/>
      <br/>
        <nav className="offside-menu">
          <ul className="left-offsidemenu">
            <li><Link to="/tops" className={isCurrentPage('/tops') ? 'actively' : ''}>TOPS </Link></li>
            <li><Link to="/bottoms" className={isCurrentPage('/bottoms') ? 'actively' : ''}>BOTTOMS</Link></li>
            <li><Link to="/outerwear" className={isCurrentPage('/outerwear') ? 'actively' : ''}>OUTERWEAR</Link></li>
            {/* <li><Link to="/knitwear" className={isCurrentPage('/knitwear') ? 'actively' : ''}>KNITWEAR</Link></li> */}
            <li><Link to="/denim" className={isCurrentPage('/denim') ? 'actively' : ''}>DENIM</Link></li>
            <li><Link to="/accessories" className={isCurrentPage('/accessories') ? 'actively' : ''}>ACCESSORIES</Link></li>
            <li><Link to="/allitems" className={isCurrentPage('/allitems') ? 'actively' : ''}>SHOP ALL</Link></li>
           
          </ul>
        </nav>
        <div className="offside-text">
          <a href="#">contact@oyeleke.com</a>
        </div>
      </div>
    </div>

    <div className="mfp-hide modalLeftOffside" id="left-offside-popup3" style={{width: '100%'}}>
      <div className="leftoffside-content-wrap">
      <i className='pe pe-7s-angle-left-circle modalActive' style={{fontSize: '40px'}} data-mfp-src="#left-offside-popup"></i>
      <br/>
      <br/>
      <br/>
        <nav className="offside-menu">
          <ul className="left-offsidemenu">
          <li><Link to="/theresa" className={isCurrentPage('/theresa') ? 'actively' : ''}>THERESA </Link></li>
          <li><Link to="/wilderness_pt2" className={isCurrentPage('/wilderness_pt2') ? 'actively' : ''}>WILDERNESS PT2 <small>(THE OTHER SIDE)</small></Link></li>
          <li><Link to="/wilderness" className={isCurrentPage('/wilderness') ? 'actively' : ''}>WILDERNESS COLLECTION</Link></li>
          <li><Link to="/divinemercycampaign" className={isCurrentPage('/divinemercycampaign') ? 'actively' : ''}>DIVINE MERCY</Link></li>
        <li><Link to="/lookbook" className={isCurrentPage('/lookbook') ? 'actively' : ''}>YOUTH ENERGY</Link></li>
          </ul>
        </nav>
        <div className="offside-text">
          <a href="#">contact@oyeleke.com</a>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>

    {window.innerWidth <= 768 ? (
                   <div>
                   <div className="footerz">
                   <a href="/allitems" className={`footerz-button ${location.pathname === '/allitems' ? 'actively' : ''}`}>
                     Shop
                   </a>
                   <a href="/theresa" className={`footerz-button ${location.pathname === '/theresa' ? 'actively' : ''}`}>
                     Theresa F/W 24
                   </a>
                   <a href="/contact" className={`footerz-button ${location.pathname === '/contact' ? 'actively' : ''}`}>
                    Contact us
                   </a>
                 </div>
               </div>
                ) : (
                  <p></p>
                )}


  
  </div>

  
  );
}

export default Footer;
