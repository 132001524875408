import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import Homepage from './component/pages/homepage';
import About from './component/pages/aboutdivinemercy';
import Aboutus from './component/pages/aboutus';
import Register from './component/pages/register';
import Accessories from './component/pages/accessories';
import AllItems from './component/pages/all';
import Bottoms from './component/pages/bottoms';
import Contact from './component/pages/contact';
import Denim from './component/pages/denim';
import Divinemercy from './component/pages/divinemercy';
import Divinemercycampaign from './component/pages/divinemercycampaign';
import Divinemercyfilm from './component/pages/divinemercyfilm';
import Film from './component/pages/film';
import Knitwear from './component/pages/knitwear';
import Lookbook from './component/pages/lookbook';
import Outerwear from './component/pages/outerwear';
import Press from './component/pages/press';
import Theresalookbook from './component/pages/theresalookbook';
import Shipping from './component/pages/shipping';
import Terms from './component/pages/terms';
import Privacy from './component/pages/privacy';
import SingleBlog from './component/pages/single-blog';
import Account from './component/pages/account';
import Template from './component/pages/template';
import Theresa from './component/pages/theresa';
import Theresafilm from './component/pages/theresafilm';
import Tops from './component/pages/tops';
import Wilderness_pt2 from './component/pages/wilderness_pt2';
import Wilderness_pt2Film from './component/pages/wilderness_pt2Film';
import Wilderness from './component/pages/wilderness';
import Wildernesslookboook from './component/pages/wildernesslookbook';
import Wilderness_pt2lookbook from './component/pages/wilderness_pt2lookbook';
import Search from './component/pages/search';
import Wildernessfilm from './component/pages/wildernessfilm';

export default function BasicExample() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Homepage}/>
           <Route path="/aboutus" component={Aboutus}/>
          <Route path="/accessories" component={Accessories}/>
          <Route path="/wildernesslookboook" component={Wildernesslookboook}/>
          <Route path="/Wilderness_pt2lookbook" component={Wilderness_pt2lookbook}/>
          <Route path="/account" component={Account}/>
          <Route path="/theresa" component={Theresa}/>
          <Route path="/theresafilm" component={Theresafilm}/>
          <Route path="/theresalookbook" component={Theresalookbook}/>
          <Route path="/register" component={Register}/>
          <Route path="/shipping" component={Shipping}/>
          <Route path="/terms" component={Terms}/>
          <Route path="/privacy" component={Privacy}/>
          <Route path="/allitems" component={AllItems}/>
          <Route path="/bottoms" component={Bottoms}/>
          <Route path="/contact" component={Contact}/>
          <Route path="/denim" component={Denim}/>
          <Route path="/search" component={Search}/>
          <Route path="/divinemercy" component={Divinemercy}/>
          <Route path="/divinemercycampaign" component={Divinemercycampaign}/>
          <Route path="/divinemercyfilm" component={Divinemercyfilm}/>
          <Route path="/film" component={Film}/>
          <Route path="/knitwear" component={Knitwear}/>
          <Route path="/lookbook" component={Lookbook}/>
          <Route path="/outerwear" component={Outerwear}/> 
          <Route path="/aboutdivinemercy" component={About}/>
          <Route path="/press" component={Press}/>
          <Route path="/single-blog" component={SingleBlog}/>
          <Route path="/template" component={Template}/>
          <Route path="/tops" component={Tops}/>
          <Route path="/wilderness_pt2" component={Wilderness_pt2}/>
          <Route path="/wilderness_pt2Film" component={Wilderness_pt2Film}/>
          <Route path="/wilderness" component={Wilderness}/>
          <Route path="/wildernessfilm" component={Wildernessfilm}/>
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.


