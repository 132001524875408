import React, {useState} from 'react';
import Header from './header'; // Import your Header component
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from './footer'; 



function Template(){

  const images = [
    'assets/img/img/DSC00702.jpg',
    'assets/img/img/DSC00538.jpg',
    'assets/img/img/DSC00541.jpg',
    'assets/img/img/DSC00573.jpg',
    'assets/img/img/DSC00569.jpg',
    'assets/img/img/DSC00587.jpg',
    'assets/img/img/DSC00593.jpg',
    'assets/img/img/DSC00624.jpg',
    'assets/img/img/DSC00628.jpg',
    'assets/img/img/DSC00636.jpg',
    'assets/img/img/DSC00641.jpg',
    'assets/img/img/DSC00727.jpg',
    'assets/img/img/DSC00730.jpg',
    'assets/img/img/DSC00746.jpg',
    'assets/img/img/DSC00743.jpg',
    'assets/img/img/DSC00612.jpg',
    'assets/img/img/DSC00663.jpg',
  ];

  const [selectedImage, setSelectedImage] = useState('');
  
  const handleShow = (image) => {
    setSelectedImage(image);
  };

      return (
        <div>
        <Header/>
        <br/>
        <br/>
        <br/> 
        <br/>
        <br/>
        <br/>
        <center style={{marginLeft: '-3%'}}> <p> <a href='/film' style={{color: 'black'}}> FILM </a> &nbsp;&nbsp;|&nbsp; <a style={{color: 'black'}} href='/lookbook'> CAMPAIGN </a> &nbsp;&nbsp;|&nbsp;  <a style={{color: 'black', fontWeight: '900'}} href='/template'> LOOKBOOK</a> &nbsp;&nbsp;&nbsp; </p></center>
        <br/> 
        <br/>
        <div>
        <div className="special-category-banner my-banner">
        <div className="special-category-banner-content">
        <div className="row justify-content-center">
          {images.map((image, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-4 mb-4" onClick={() => handleShow(image)} data-bs-toggle="modal" data-bs-target="#quickViewModal">
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="img-fluid w-100"
              />
            </div>
          ))}
        </div>
        </div>
        </div>
  
        <div className="modal" id="quickViewModal" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <Carousel selectedItem={images.indexOf(selectedImage)} showThumbs={false}>
              {images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`Single Product ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        </div>
        </div>
        </div>
        <Footer/>
            </div>
               
        );
 
  };

export default Template;
