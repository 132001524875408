import React from 'react';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import './mystyle.css';


function Press(){


      return (
              <div>
     <Header/>
     <br/>
     <br/>
     <br/> 
     <br/>
     <div className="page-header-wrapper layout-two" style={{backgroundImage: 'url(assets/img/img/DSC00823.jpg) !important', backgroundPosition: 'center'}}>
         <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="page-header-content">
                  <h2>PRESS</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="blog-page-wrapper" className="pt-90 pt-md-60 pt-sm-50 pb-48 pb-md-20 pb-sm-10">
          <div className="container">
            <div className="blog-page-post-wrap">
              <div className="row">
                {/* Start Single Blog Item */}
                {/* <div className="col-md-12 col-lg-12">
                  <div className="single-blog-post-wrap">
                    <figure className="blog-post-thumbnail">
                      <a href="single-blog"><img src="assets/img/img/bobbytrendz.png" alt="Blog" /></a>
                    </figure>
                    <div className="blog-post-content">
                      <div className="blog-meta">
                        <a href="single-blog" className="author">By Bobola Oyeleke</a>
                        <span> - </span>
                        <a href="single-blog" className="date">August 14, 2020</a>
                        <span> / </span>
                      </div>
                      <h2><a href="single-blog">BOBBY TRENDZ NAMED DIRECTOR OF PUBLIC RELATIONS AT OYELEKE
                        </a></h2>
                      <p>BOBBY TRENDZ, vibe curator and visual enthusiast, is now the Director<br /> of Public Relations at the newly developed clothing brand, 
                        OYELEKE.<br /> As Director, Bobby Trendz will be responsible for leading outward..</p>
                      <a href="single-blog" className="btn btn-transparent">Read More</a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <br />
            <br />
            <br />
            <div className="blog-page-post-wrap">
              <div className="row">
                {/* Start Single Blog Item */}
                <div className="col-md-6 col-lg-6">
                  <div className="single-blog-post-wrap">
                    <figure className="blog-post-thumbnail">
                      <a href="https://oyelekefoundation.org" target="_blank"><img src="assets/img/img/oyelekefoundation.PNG" alt="Blog" /></a>
                    </figure>
                    <div className="blog-post-content">
                      <div className="blog-meta">
                        <a href="https://oyelekefoundation.org" target="_blank" className="author">By Bobola Oyeleke</a>
                        <span> - </span>
                        <a href="https://oyelekefoundation.org" target="_blank" className="date">June 11, 2020</a>
                        <span> / </span>
                      </div>
                      <h2><a href="https://oyelekefoundation.org" target="_blank">We Proudly support the OYELEKE Foundation
                        </a></h2>
                      {/* <p>BOBBY TRENDZ, vibe curator and visual enthusiast, is now the Director of Public Relations at the newly developed clothing brand, 
                        OYELEKE. As Director, Bobby Trendz will be responsible for leading outward..</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     <Footer/>
              </div>
               
        );
 
  };

export default Press;
