import React, {useState} from 'react';
import Header from './header'; // Import your Header component
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from './footer'; 



function Theresafilm(){

  const images = [
    'assets/media/theresa/8A5CC55E-9473-47C5-B1AD-C2C9F1B0078C.JPG',
    'assets/media/theresa/8F6BE31A-E832-4E59-93E5-19A1844EE919.JPG',
    'assets/media/theresa/B2144967-206A-4B02-8EEF-BD8944F38A90.JPG',
    'assets/media/theresa/201F79B2-3E06-462B-BAFB-AA172E178014.JPG',
    'assets/media/theresa/519142BA-A572-48AF-93D1-BFB69A916707.JPG',
    'assets/media/theresa/9804F6DC-DB07-4ADD-82D1-E3A9D1DE375D.JPG',
    'assets/media/theresa/23A2C5AE-24D5-43DA-B691-6EB3CCE73C69.JPG',
    'assets/media/theresa/5E1D956E-6E33-4ECD-B142-DCB6932EFE0F.JPG',
    'assets/media/theresa/3CA66908-FA4A-45E4-BDBE-09BBAC36CBA0.JPG',
    'assets/media/theresa/600CA579-F32A-43DD-B927-1468C15A637B.JPG',
    'assets/media/theresa/B27CFC02-A7D2-4F6B-99A8-E1DB30961AC8.JPG',
    'assets/media/theresa/BCD5C739-6A83-41D8-9A5E-AE374A547F45.JPG',
    'assets/media/theresa/C3A11485-9DA1-4F04-B5AF-D765A81FC5D8.JPG',
    'assets/media/theresa/6CE3E564-5FED-4C0C-8447-0DC7F2CCFCAD.JPG',
    'assets/media/theresa/C11C04E8-39D1-4DB7-8FC3-5D39E77AA436.JPG',
    'assets/media/theresa/FB1683C9-C264-45A4-8BAE-77E9A9AF1BC6.JPG',
    'assets/media/theresa/B4F11311-927D-4E1C-A1DA-5B91A2BE14BB.JPG',
    'assets/media/theresa/3CE6547D-9811-4EC6-87CE-22CDF2205D37.JPG',
    'assets/media/theresa/6FF72A11-0444-47FD-8F86-E70ABA12348A.JPG',
    'assets/media/theresa/461A5092-0581-449F-8E80-52BAF018543B.JPG',
    'assets/media/theresa/336E53C8-6202-4EF0-A24B-EF25A9318074.JPG',
    'assets/media/theresa/6E4C87AB-CD13-44F8-8E6A-7DE7F14C43A3.JPG',
    'assets/media/theresa/B616314E-7037-47B4-AAAD-A233BFF343FB.JPG',
    'assets/media/theresa/3646F5FB-2ADD-4DC1-A407-F52660C8B541.JPG',
    'assets/media/theresa/235B62C2-16D9-4CCB-8973-A28BA2DD9621.JPG',
    'assets/media/theresa/31C7A93D-65B5-42CD-AF8E-9D474472936B.JPG',
    'assets/media/theresa/1CC6EEA2-A2CC-4D0B-9637-A11A701F43D2.JPG',
    'assets/media/theresa/51CE6022-FE2A-4F17-B51C-096C2CDD495E.JPG',
    'assets/media/theresa/AD666A2B-B964-4F63-8C0C-43AF0FBA03CC.JPG',

    
    

    
  ];

  const [selectedImage, setSelectedImage] = useState('');
  
  const handleShow = (image) => {
    setSelectedImage(image);
  };

      return (
        <div>
        <Header/>
        <br/>
        <br/>
        <br/> 
        <br/>
        <br/>
        <br/>
        <center style={{marginLeft: '-3%'}}> <p> <a href='/theresafilm' style={{color: 'black'}}> FILM </a> &nbsp;&nbsp;|&nbsp; <a style={{color: 'black'}} href='/theresa'> CAMPAIGN </a> &nbsp;&nbsp;|&nbsp;  <a style={{color: 'black', fontWeight: '900'}} href='/theresalookbook'> LOOKBOOK</a> &nbsp;&nbsp;&nbsp; </p></center>
        {/* <br/>    */}
        {/* <br/> */}
        <div>
        <div className="special-category-banner my-banner">
        <div className="special-category-banner-content">
        <div className="row justify-content-center">
          {images.map((image, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-4 mb-4" onClick={() => handleShow(image)} data-bs-toggle="modal" data-bs-target="#quickViewModal">
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="img-fluid w-100"
              />
            </div>
          ))}
        </div>
        </div>
        </div>
  
        <div className="modal" id="quickViewModal" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <Carousel selectedItem={images.indexOf(selectedImage)} showThumbs={false}>
              {images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`Single Product ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        </div>
        </div>
        </div>
        <Footer/>
            </div>
               
        );
 
  };

export default Theresafilm;
