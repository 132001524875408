import React, {useState} from 'react';
import Header from './header'; // Import your Header component
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from './footer'; 

function Lookbook(){
  const images = [
    'assets/img/img/DSC00786.jpg',
    'assets/img/img/DSC01073.jpg',
    'assets/img/img/DSC01070.jpg',
    'assets/img/img/DSC00822-2.jpg',
    'assets/img/img/DSC00823.jpg',
    'assets/img/img/DSC00832.jpg',
    'assets/img/img/DSC00845.jpg',
    'assets/img/img/DSC01037.jpg',
    'assets/img/img/DSC01039.jpg',
    'assets/img/img/DSC00936-2.jpg',
    'assets/img/img/DSC01021.jpg',
    'assets/img/img/DSC01023.jpg',
    'assets/img/img/DSC01104.jpg',
    'assets/img/img/DSC01084.jpg',
    'assets/img/img/DSC01009.jpg',
    'assets/img/img/DSC00905.jpg',
    'assets/img/img/DSC00967.jpg',
    'assets/img/img/DSC00961.jpg',
    'assets/img/img/DSC01202.jpg',
    'assets/img/img/DSC00975.jpg',
    'assets/img/img/DSC01048.jpg',
    'assets/img/img/DSC01136.jpg',
    'assets/img/img/DSC01148.jpg',
    'assets/img/img/DSC01181.jpg',
    'assets/img/img/DSC01198.jpg',
    'assets/img/img/DSC00970.jpg',
                  ]

  const [selectedImage, setSelectedImage] = useState('');
  const handleShow = (image) => {
    setSelectedImage(image);
  };
      return (
        <div>
        <Header/>
        <br/>
        <br/>
        <br/> 
        <br/>
        <br/>
        <br/>
       <center style={{marginLeft: '-3%'}}> <p> <a href='/film' style={{color: 'black'}}> FILM </a> &nbsp;&nbsp;|&nbsp; <a style={{color: 'black', fontWeight: '700'}} href='/lookbook'> CAMPAIGN </a> &nbsp;&nbsp;|&nbsp;  <a style={{color: 'black'}} href='/template'> LOOKBOOK</a> &nbsp;&nbsp;&nbsp; </p></center>
        <br/> 
        <br/>
        <div>
        <div className="special-category-banner my-banner">
        <div className="special-category-banner-content">
        <div className="row justify-content-center">
          {images.map((image, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-4 mb-4" onClick={() => handleShow(image)} data-bs-toggle="modal" data-bs-target="#quickViewModal">
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="img-fluid w-100"
              />
            </div>
          ))}
        </div>
        </div>
        </div>
  
        <div className="modal" id="quickViewModal" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <Carousel selectedItem={images.indexOf(selectedImage)} showThumbs={false}>
              {images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`Single Product ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        </div>
        </div>
        </div>
        <Footer/>
            </div>
            
        );
  
        };

export default Lookbook;
