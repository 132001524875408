import React from 'react';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component


function Aboutus(){


      return (
              <div>
     <Header/>
     <br/>
     <br/>
     <br/> 
     <br/>
   <div className="page-header-wrapper layout-two">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="page-header-content">
                  <h2>YOUTH ENERGY</h2>
                  <nav className="page-breadcrumb">
                    <ul className="d-flex justify-content-center">
                      <li><a href="aboutdivinemercy">DIVINE MERCY</a></li>
                      <li><a href="aboutus">YOUTH ENERGY</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="about-page-wrapper" className="pt-90 pt-md-60 pt-sm-50 pb-50 pb-md-20 pb-sm-10">
          <div className="about-content-wrap mb-84 mb-md-54 mb-sm-46">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 mb-sm-28 mb-md-24">
                  <div className="about-thumbnail">
                    <img src="assets/img/img/about2.jpeg" alt="About Thumbnail" />
                  </div>
                </div>
                <div className="col-lg-6 ms-auto my-auto">
                  <div className="about-content">
                    <h2>OYELEKE | YOUTH ENERGY 20’</h2>
                    <p>Oyeleke is a “Sophi – Eccentric Gentle Avant-Garde” brand founded in 2018 by Bobola Oyeleke. Characterized by merging the finest materials with deconstructed traditional shapes/dimensions, Bobola Oyeleke aims to create new silhouettes for the modern man.</p>
                    <p>Bobola Oyeleke is a former minor seminarian with a BSc in Economics, MBA and most recently completed a Business Analytics program at Harvard Business School. He was born and raised in Nigeria, lived in Texas briefly before moving to Los Angeles, where he currently designs software technology infrastructure for DISNEY.</p>
                    <p>His first presentation, Youth Energy, which he calls a fashion mixtape, features oversized wide legged pants, washed denim and hand painted splatter details while highlighting the OYELEKE signature rustic adjustable buckle with extra length belts.</p>
                    <p>The Youth Energy collection which was conceptualized and designed in over 17 European cities from classic Old town Prague, mid-century modern Reykjavík to modern Monte Carlo was originally started in 2018 and is a testament of his attention to detail and search for perfection. The follow up [album] to this presentation [mixtape] which he calls DIVINE MERCY, is currently being perfected and is stated to be presented in 2021.</p>
                    {/* <p>The full list of the promises attached to divine mercy are here: https://fathersofmercy.com/promises- attached-to-chaplet-of-divine-mercy/ I will come back to Divine Mercy in future collections, to preach and remind us all of God's unending love and mercy. It's so important and might in-fact, be the only hope of saving the human race.</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     <Footer/>
              </div>
               
        );
 
  };

export default Aboutus;
