import React, {useState} from 'react';
import Header from './header'; // Import your Header component
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from './footer'; // Import your Footer component
// import './mystyle.css';


function Wildernesslookboook(){

  const images = [
    'assets/img/img/wilderness/O-10.jpg',
    'assets/img/img/wilderness/O-07.jpg',
    'assets/img/img/wilderness/O-17.jpeg',
    'assets/img/img/wilderness/O-02.jpg',
    'assets/img/img/wilderness/O-03.jpg',
    'assets/img/img/wilderness/O-12.jpeg',
    'assets/img/img/wilderness/O-13.jpeg',
    'assets/img/img/wilderness/O-15.jpeg',
    'assets/img/img/wilderness/O-04.jpg',
    'assets/img/img/wilderness/O-05.jpg',
    'assets/img/img/wilderness/O-08.jpg',
    'assets/img/img/wilderness/O-09.jpg',
    
  ];

  const [selectedImage, setSelectedImage] = useState('');
  
  const handleShow = (image) => {
    setSelectedImage(image);
  };


      return (
              <div>
     <Header/>
     <br/>
     <br/>
     <br/> 
     <br/>
     <br/>
     <br/>
     <br/> 
     <center style={{marginLeft: '-3%'}}> <p><a style={{color: 'black'}} href='/wildernessfilm'> FILM </a> &nbsp;&nbsp;|&nbsp; <a style={{color: 'black'}} href='wilderness'> CAMPAIGN </a> &nbsp;&nbsp;|&nbsp;  <a style={{color: 'black', fontWeight: '700'}} href='wildernesslookboook'> LOOKBOOK</a> &nbsp;&nbsp;&nbsp; </p></center>
     <br/>
     <div>
      <div className="special-category-banner my-banner">
        <div className="special-category-banner-content">
          <div className="row justify-content-center">
            {images.map((image, index) => (
              <div key={index} className="col-12 col-sm-6 col-md-4 mb-4" onClick={() => handleShow(image)} data-bs-toggle="modal" data-bs-target="#quickViewModal">
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  className="img-fluid w-100"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="modal" id="quickViewModal" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <Carousel selectedItem={images.indexOf(selectedImage)} showThumbs={false}>
                {images.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt={`Single Product ${index + 1}`} />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
     <Footer/>
              </div>
               
        );
 
  };

export default Wildernesslookboook;
