import React from 'react';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component

function Contact(){

      return (
              <div>
     <Header/>
     <br/>
     <br/>
     <br/> 
     <br/>
     <div className="page-header-wrapper layout-two">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="page-header-content">
                  <h2>Contact Us</h2>
                  <nav className="page-breadcrumb">
                    <ul className="d-flex justify-content-center">
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="contact-page-wrapper" className="pt-90 pt-md-60 pt-sm-50 pb-50 pb-md-20 pb-sm-10">
          <div className="contact-page-top-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-6">
                  <div className="contact-page-form-wrap contact-method">
                    <h3>Get in touch</h3>
                    <div className="contact-form-wrap">
                      <form action="https://getform.io/f/f6ea9748-e6ce-4a01-8218-05d889fa7a04" method="post">
                        <div className="single-input-item">
                          <input type="text" name="first_name" placeholder="Your Name *" required />
                        </div>
                        <div className="single-input-item">
                          <input type="email" name="email_address" placeholder="Email address *" required />
                        </div>
                        <div className="single-input-item">
                          <input type="text" name="phone_no" placeholder="Your Phone *" required />
                        </div>
                        <div className="single-input-item">
                          <textarea name="message"  cols={30} rows={7} placeholder="Message *" required defaultValue={""} />
                        </div>
                        <button className="btn btn-black">Send Message</button>
                        <div className="form-message" />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 mt-sm-50">
                  <div className="contact-info-wrapper contact-method">
                    <h3>Contact Info</h3>
                    <div className="contact-info-content">
                      <div className="single-contact-info">
                        {/* <h4>Postal Address</h4>
                        <p>1200 South Figueroa street, <br /> Los Angeles, CALIFORNIA 90015, USA */}
                        {/* </p> */}
                        </div>
                      <div className="single-contact-info">
                        {/* <h4>OYELEKE HQ</h4>
                        <p>1200 South Figueroa street, <br /> Los Angeles, CALIFORNIA 90015, USA
                        </p> */}
                        </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="single-contact-info mb-0">
                            <h4>Say Hello</h4>
                            <p>CONTACT@OYELEKE.COM</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     <Footer/>
              </div>
               
        );
 
  };

export default Contact;
