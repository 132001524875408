import React, {useState} from 'react';
import Header from './header'; // Import your Header component
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from './footer'; 



function Theresa(){

  const images = [
    'assets/media/theresa/theresa2.jpeg',
    'assets/media/theresa/theresa3.jpeg',
    'assets/media/theresa/theresa4.jpeg',



    'assets/media/image3.jpeg',
    'assets/media/image1.jpeg',
    'assets/media/image0.jpeg',
    'assets/media/image4.jpeg',
    'assets/media/image2.jpeg',
    'assets/media/image5.jpeg',
    'assets/media/theresa/theresa1.jpeg',
    'assets/media/theresa/theresa5.jpeg',
    'assets/media/theresa/theresa6.jpeg',
    'assets/media/theresa/theresa7.jpeg',
    'assets/media/theresa/theresa8.jpeg',
    'assets/media/theresa/theresa9.jpeg',
    'assets/media/theresa/theresa10.jpeg',
    'assets/media/theresa/theresa11.jpeg',
    'assets/media/theresa/theresa12.jpeg',
    'assets/media/theresa/theresa13.jpeg',
    'assets/media/theresa/theresa14.jpeg',
    'assets/media/theresa/theresa18.jpeg',
    'assets/media/theresa/theresa16.jpeg',
    'assets/media/theresa/theresa19.jpeg',
    'assets/media/theresa/theresa22.jpeg',
    'assets/media/theresa/theresa20.jpeg',
    'assets/media/theresa/theresa15.jpeg',
    'assets/media/theresa/theresa23.jpeg',

    // 'assets/media/theresa/theresa21.jpeg',

    // 'assets/media/theresa/theresa17.jpeg',
  ];

  const [selectedImage, setSelectedImage] = useState('');
  
  const handleShow = (image) => {
    setSelectedImage(image);
  };

      return (
        <div>
        <Header/>
        <br/>
        <br/>
        <br/> 
        <br/>
        <br/>
        <br/>
        <center style={{marginLeft: '-3%'}}> <p> <a href='/theresafilm' style={{color: 'black'}}> FILM </a> &nbsp;&nbsp;|&nbsp; <a style={{color: 'black', fontWeight: '900'}} href='/theresa'> CAMPAIGN </a> &nbsp;&nbsp;|&nbsp;  <a style={{color: 'black'}} href='/theresalookbook'> LOOKBOOK</a> &nbsp;&nbsp;&nbsp; </p></center>
        {/* <br/>    */}
        {/* <br/> */}
        <div>
        <div className="special-category-banner my-banner">
        <div className="special-category-banner-content">
        <div className="row justify-content-center">
          {images.map((image, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-4 mb-4" onClick={() => handleShow(image)} data-bs-toggle="modal" data-bs-target="#quickViewModal">
              <img
                src={image}
                alt={`Image ${index + 1}`}
                className="img-fluid w-100"
              />
            </div>
          ))}
        </div>
        </div>
        </div>
  
        <div className="modal" id="quickViewModal" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <Carousel selectedItem={images.indexOf(selectedImage)} showThumbs={false}>
              {images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`Single Product ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        </div>
        </div>
        </div>
        <Footer/>
            </div>
               
        );
 
  };

export default Theresa;
