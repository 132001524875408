import React from 'react';
import Header from './header'; 
import Footer from './footer';
import './mystyle.css';


function Shipping(){
      return (
              <div>
     <Header/>
     <br/>
     <br/>
     <br/> 
     <br/>
        <div id="blog-page-wrapper" className="pt-90 pt-md-60 pt-sm-50 pb-48 pb-md-20 pb-sm-10">
          <div className="container">
            <div className="blog-page-post-wrap">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="single-blog-post-wrap">
                    <p style={{textAlign: 'justify', lineHeight: '2.0',}}>
                    <h4>SHIPPING POLICY</h4>
                    <br/>
                    <h5>SHIPPING AND DELIVERY INFORMATION</h5>
                    <br/>
                    <br/>
                    
                     <b>AMENDING A SHIPPING ADDRESS</b>
                        <br/>
                        <p style={{textTransform: 'uppercase',lineHeight: '2.0'}}>
                        WE CANNOT CHANGE THE SHIPPING ADDRESS OR THE SHIPPING METHOD SELECTED AFTER CHECKOUT.
                            <br/>
                            <br/>
                        ONCE AN ORDER HAS BEEN SHIPPED, AN EMAIL WILL BE SENT TO THE EMAIL ADDRESS PROVIDED ON THE ORDER WITH YOUR SHIPMENT'S TRACKING INFORMATION. CUSTOMERS ARE RESPONSIBLE FOR MONITORING THE DELIVERY OF THEIR SHIPMENT ONCE AN ORDER HAS LEFT OUR WAREHOUSE.
                        <br/> 
                        <br/>
                       <b> U.S. SHIPPING OPTIONS AND COSTS</b>
                       <br/>
                         OYELEKE OFFERS FREE STANDARD GROUND SHIPPING FOR ALL FULL PRICE MAINLINE ORDERS SHIPPING WITHIN THE UNITED STATES.  ORDERS TAKE 4-7 BUSINESS DAYS TO ARRIVE ONCE YOUR ORDER LEAVES OUR DISTRIBUTION CENTER IN LOS ANGELES.
                            FOR ALL OTHER PURCHASES, SHIPPING COSTS WILL BE CALCULATED AT CHECKOUT AND WILL BE BASED ON YOUR SHIPPING ADDRESS AND ESTIMATED WEIGHT OF YOUR PACKAGE.
                    
                        <br/>
                       <br/>
                       <b>DUE TO COVID-19 STAFFING PRECAUTIONS, WE DO NOT HAVE EXPEDITED SHIPPING AVAILABLE AT THIS TIME.</b>
                       <br/>
                       FOR DELIVERIES TO ALASKA, HAWAII AND PUERTO RICO, YOUR ORDER WILL BE AUTOMATICALLY UPGRADED TO AIR DELIVERY AT CHECKOUT.
                        WE ARE UNABLE TO COMBINE SEPARATE ORDERS INTO A SINGLE SHIPMENT.
                        <br/>
                       <br/>
                        WE DO NOT SHIP TO PO BOXES.
                        <br/>
                       <br/>
                        <b>INTERNATIONAL SHIPPING OPTIONS AND COSTS</b>
                       <br/>
                        INTERNATIONAL SHIPPING RATES VARY.  DURING CHECKOUT WE CALCULATE SHIPPING COSTS BASED ON YOUR LOCATION AND ESTIMATED WEIGHT OF YOUR PACKAGE.
                        <br/>
                       <br/>
                       <b>FAILED DELIVERY/ RETURNED TO SENDER/ ABANDONED PACKAGES</b>
                       <br/> 
                       IF YOUR PACKAGE CANNOT BE DELIVERED TO THE GIVEN SHIPPING ADDRESS DUE TO INCORRECT SHIPPING ADDRESS AND/OR PHONE NUMBER, THE PACKAGE WILL BE RETURNED TO THE SENDER (OYELEKE) AT THE CUSTOMER’S EXPENSE. 
                       <br/>  
                       <br/>  
                         IF THE RECEIVER IS ABSENT UPON ALL DELIVERY ATTEMPTS, YOUR PACKAGE WILL BE HELD AT A LOCAL FEDEX CENTER FOR PICK UP.  IF THE CUSTOMER FAILS TO PICK UP THE PACKAGE FOR ANY REASON, THE PACKAGE WILL BE RETURNED TO THE SENDER (OYELEKE) AT THE CUSTOMER’S EXPENSE.
                        <br/>  
                        <br/>  
                        FOR INTERNATIONAL CUSTOMERS, IF YOU REFUSE RECEIPT OF YOUR PACKAGE, THE PACKAGE WILL BE RETURNED TO OYELEKE AT YOUR EXPENSE. THE EXPENSE INCLUDES SHIPPING COSTS AND ANY DUTY AND TAX THE PACKAGE MAY HAVE INCURRED; THIS AMOUNT WILL BE DEDUCTED FROM THE TOTAL OF THE AMOUNT TO BE REFUNDED.
                        <br/>  
                        <br/>  
                        PACKAGES WILL BE HELD AT CUSTOMS FOR 30 DAYS, AFTER WHICH IT WILL BE DEEMED ABANDONED. CUSTOMERS WHO HAVE ABANDONED A PACKAGE WILL FORFEIT THE RIGHT TO AN EXCHANGE OR REFUND FOR THEIR ORDER. 
                        <br/>  
                        <br/>  
                       <b>SHOPPING BAGS</b>
                       <br/>  
                       WHEN YOU SHOP ON OUR SITE, YOU CAN USE A SHOPPING BAG TO KEEP TRACK OF THE ITEMS YOU HAVE SELECTED UNTIL YOU ARE DONE SHOPPING.  YOU WILL BE ABLE TO REVIEW AND CONFIRM YOUR CHOICES, YOUR SHIPPING ADDRESS, HOW YOU ARE PAYING FOR YOUR ITEMS, AND THE SIZES YOU NEED.
                        <br/>
                        <br/>
                        <b>DAMAGED/OPENED BOX</b>
                        <br/>
                        IF YOU RECEIVE YOUR PACKAGE, AND IT IS DAMAGED AND/OR OPENED, PLEASE TAKE A PHOTO OF THE DAMAGE BEFORE CHECKING THE CONTENTS INSIDE. PLEASE SUBMIT A CLAIM VIA THE COURIER USED TO SHIP YOUR ORDER. PLEASE ALSO CONTACT US AT INFO@OYELEKE.COM.
                        <br/>
                        <br/>
                        </p>
                    </p>
                   
                    
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

     <Footer/>
              </div>
               
        );
 
  };

export default Shipping;
